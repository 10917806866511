import React, { Fragment, useEffect, useRef, useState } from "react";
import { DateBadge } from "../../badges";
import { IoReturnUpBack } from "../../../react-icons/io5";
import { IoIosAttach } from "../../../react-icons/io";
import { SearchInputText } from "../searchInput/parts/SearchInputText";
import classNames from "classnames";

type Props = {
  search: string;
  setSearch: (arg: string) => void;
  isLoading?: boolean;
  results?: any[];
  setResults?: (arg: any[]) => void;
  cb?: (arg: any) => void;
  type: "accountingFirm" | "company" | "user" | "report";
  maxWidth?: string;
  className?: string;
};

export function SearchWithResults({ search, setSearch, isLoading, results = [], setResults = () => { }, cb = () => { }, type, maxWidth = "550px", className }: Props) {
  let placeholderMessage = "";
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef(null);

  switch (type) {
    case "accountingFirm":
      placeholderMessage = "Entrez le nom du cabinet";
      break;
    case "company":
      placeholderMessage = "Entrez le nom du dossier";
      break;
    case "user":
      placeholderMessage = "Entrez le nom de l'utilisateur";
      break;
    case "report":
      placeholderMessage = "Entrez le n° de ticket";
      break;
    default:
      placeholderMessage = "Entrez votre recherche";
  }

  const handleClick = (e) => {
    if (searchRef?.current && !searchRef?.current.contains(e.target)) {
      setIsOpen(false);
      searchRef?.current?.focus();
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);

    if (results?.length > 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    return () => {
      removeEventListener("click", handleClick);
    };
  }, [results]);

  return (
    <div className={classNames(className, "column searchInputWithResults")} style={{ position: "relative" }}>
      <SearchInputText value={search} setter={setSearch} isLoading={isLoading} placeholder={placeholderMessage} />
      {results && isOpen && (
        <div
          ref={searchRef}
          className="results"
          style={{
            borderRadius: "15px",
            background: "var(--color-gray1)",
            filter: "drop-shadow(0 0 10px #0000004f)",
            padding: "15px",
            position: "absolute",
            top: "100%",
            zIndex: 10,
            width: "100%",
            maxWidth,
            display: "flex",
            flexDirection: "column",
            gap: "10px"
          }}
        >
          {results?.map((item, key) => {
            return (
              <Fragment key={key}>
                {type === "accountingFirm" && (
                  <div
                    className="item"
                    onClick={() => {
                      setIsOpen(false);
                      setResults([]);
                      cb(item[0]);
                    }}
                  >
                    {item?.[0]?.name}
                  </div>
                )}
                {type === "report" && (
                  <div
                    className="row a-center item"
                    onClick={() => {
                      cb(item);
                      setResults([]);
                      setIsOpen(false);
                    }}
                  >
                    <span>{item?.numerotation}</span>
                    <DateBadge className="m-0" size="medium">
                      {item?.createdAt}
                    </DateBadge>
                    {item?.answered && <IoReturnUpBack />}
                    {item?.hasAttachment && <IoIosAttach />}
                    <span>{item?.reportMessages.length}</span>
                    <span>{item?.lastMessage.slice(0, 30) + "..."}</span>
                  </div>
                )}
                {type === "user" && (
                  <div
                    className="item"
                    onClick={() => {
                      setIsOpen(false);
                      setResults([]);
                      cb(item);
                    }}
                  >
                    {item?.firstName} {item?.lastName}
                  </div>
                )}
                {type === "company" && (
                  <div
                    className="item"
                    onClick={() => {
                      setIsOpen(false);
                      setResults([]);
                      cb(item);
                    }}
                  >
                    {item?.name}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
}
