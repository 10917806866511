'use client';
import { HTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CriteriaType, SearchInput } from '../searchInput/SearchInput';
import { InputContainer } from '../InputContainer';
import { AccountType } from '../../../../utilities/redux/types';
import { IconButton } from '../../buttons';
import { SuggestionAccountType } from '../searchInput/parts/SearchSuggestions';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  legend?: string;
  value?: AccountType;
  setter?: (arg: AccountType) => void;
  direction?: "column" | "row";

  // Search
  handleSearch: (search: string, signal: AbortSignal, criteria?: CriteriaType, ...any) => Promise<SuggestionAccountType[]> | SuggestionAccountType[] | void;
  isLoading: boolean;

  // ERROR
  showError?: boolean;
  setError?: (error: string) => void;
}

export function AccountInput({
  className,
  handleSearch,
  isLoading,
  id,
  required = false,
  disabled = false,
  placeholder,
  direction = "column",
  legend,
  value = null,
  setter,
  setError,
  showError = false,
  ...props }: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "textInput";
  const [errorMessage, setErrorMessage] = useState("");

  // Check if value is correct
  function error(targetValue: string) {
    if (required && !targetValue) {
      const errorComputed = "Vous devez choisir un code APE";

      setError && setError(errorComputed);

      return errorComputed;
    }
    return "";
  }

  const handleChange = (e) => {
    setErrorMessage(error(e?.code));
    setter(e);
  };

  useEffect(() => {
    setErrorMessage(error(value?.code));
  }, [value]);

  return (
    <InputContainer {...props} className={classNames("account-input", className)} errorMessage={errorMessage} showError={showError} direction={direction}>
      {legend && <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend} {required && "*"}
      </label>}

      {disabled
        ? <div className={"badge empty medium row a-center space-between"}>
          {value?.code ? `${value.code} - ${value.legend}` : "Aucun compte renseigné"}
        </div>
        : (value?.code
          ? <div className={"badge empty medium row a-center space-between"}>
            {value.code} - {value.legend}

            <IconButton className='ml-auto' size={18} form='circle' icon='close' cb={() => setter(null)} type='empty' />
          </div>
          : <SearchInput
            entity='account'
            placeholder={placeholder}
            aria-labelledby={`${NAMESPACE}_label`}
            id={NAMESPACE}
            isLoading={isLoading}
            handleSearch={handleSearch}
            setSuggestionSelected={handleChange}
          />)}
      { }
    </InputContainer>
  );
}