"use client";
import classNames from "classnames";
import { HTMLAttributes, useEffect } from "react";
import { ToggleInput } from "..";
import { formatDateToYYYYMMDD } from "../../../../utilities/tools/date";
import { InputContainer } from "../InputContainer";

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: string;
  setter?: (arg: string) => void;

  id?: string;
  legend?: string;
  disabled?: boolean;
  required?: boolean;
  minDate?: string;
  maxDate?: string;
  direction?: "column" | "row" | "row-reverse" | "column-reverse";
  nullable?: boolean;

  setError?: (error: string) => void;
  showError?: boolean;

  withHour?: boolean;
}

/**
 *
 * Nouveau composant Date
 *
 * -> redéfini une valeur par défault si la value n'est pas valide
 * @param [id=legend] - allow click on label
 * @param [required=false]
 * @param [disabled=false]
 * @param legend label over the input
 * @param value value of the input
 * @param setter set value of the input
 * @param setError retrieve error of the input
 * @param showError show error over the iput
 * @params maxDate (@default: "2050-01-01")
 * @params minDate (@default: "2022-01-01")
 * @params direction (@default: "vertical")
 * @params nullable (@default: false)
 *
 */
export function DateInput({
  id,
  value = new Date().toISOString().split("T")[0],
  direction = "column",
  setter,
  disabled = false,
  required = false,
  maxDate = "2050-01-01",
  minDate = "2022-01-01",
  legend,
  nullable = false,
  withHour = false,
  ...props
}: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "dateInput";

  /// Check si c'est un bon timestamp
  const checkDate = (testDate: string) => {
    let isValidDate = Date.parse(testDate);
    return !isNaN(isValidDate);
  };

  // Logique pour déterminer la valeur par défaut si aucune n'est renseignée
  const defaultValue = () => {
    if (nullable) {
      return null;
    } else {
      const today = new Date();

      if (withHour) {
        today.setHours((new Date().getHours() + 1) % 23);
        today.setMinutes(0);
      } else {
        today.setHours(23);
        today.setMinutes(59);
      }

      return minDate ?? maxDate ?? formatDateToYYYYMMDD(today, withHour);
    }
  };

  // Logique pour déterminer la valeur finale du champ
  const finalValue = (date) => {
    if (!checkDate(date)) return defaultValue();

    if (minDate && date < minDate) return minDate;
    if (maxDate && date > maxDate) return maxDate;

    return date;
  };

  const handleChange = (e) => {
    setter && e.target.value && setter(e.target.value);
  };

  const handleBlur = (e) => {
    setter && setter(finalValue(e.target.value));
  };

  const setInitValue = () => {
    if (setter) {
      if (checkDate(value) == false) {
        setter(defaultValue());
      } else {
        setter(formatDateToYYYYMMDD(new Date(value), withHour));
      }
    }
  };

  /// init value
  useEffect(() => {
    setInitValue();
  }, []);

  return (
    <InputContainer {...props} className={classNames(props.className, { disabled: disabled })} direction={direction}>
      {nullable ? (
        <ToggleInput
          legend={legend}
          value={value !== null}
          className="mt-0 mb-1"
          setter={(data) => {
            if (data) {
              const today = new Date();

              if (withHour) {
                today.setHours((new Date().getHours() + 1) % 23);
                today.setMinutes(0);
              }

              setter(minDate ?? maxDate ?? formatDateToYYYYMMDD(today, withHour));
            } else {
              setter(null);
            }
          }}
          disabled={!setter || disabled}
        />
      ) : (
        <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
          {legend ?? "date"} {required && "*"}
        </label>
      )}

      {((nullable && value !== null) || !nullable) && (
        <input
          type={withHour ? "datetime-local" : "date"}
          min={formatDateToYYYYMMDD(new Date(minDate))}
          max={formatDateToYYYYMMDD(new Date(maxDate))}
          onChange={handleChange}
          onBlur={handleBlur}
          required={required}
          aria-labelledby={`${NAMESPACE}_label`}
          id={NAMESPACE}
          value={value}
          disabled={!setter || disabled}
        />
      )}
    </InputContainer>
  );
}
