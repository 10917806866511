import { useEffect, useState } from "react";
import { Grid } from "../../../components/blocs";
import CalendarInput from "./CalendarInput";
import CalendarMonthChoice from "./CalendarMonthChoice";
import CalendarWrapperFooter from "./CalendarWrapperFooter";
import CalendarWrapperHeader from "./CalendarWrapperHeader";
import CalendarWrapperRapidSelectAside from "./CalendarWrapperRapidSelectAside";

export const CalendarWrapperInput = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [currentYear, setCurrentYear] = useState(2025);
  //// Date selection

  const handleChangeRapidDate = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    console.log(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <div style={{ overflow: "hidden" }} className="calendarWrapperInput tile mw-900">
      <div className="column w-100 h-100">
        <CalendarWrapperHeader startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />

        <Grid template="240px 400px" space={0} mediaQuery={0}>
          <CalendarWrapperRapidSelectAside onClick={handleChangeRapidDate} startDate={startDate} endDate={endDate} />
          <div className="column">
            <CalendarMonthChoice currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} />
            <CalendarInput startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
            <CalendarWrapperFooter startDate={startDate} endDate={endDate} />
          </div>
        </Grid>
      </div>
    </div>
  );
};
