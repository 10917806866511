import { SearchBold } from "../SearchBold";
import { EntityType } from "../SearchInput";

export interface SuggestionAccountType {
  id: number;
  code: string;
  legend: string;
}

export interface SuggestionUserType {
  id: number;
  firstName: string;
  lastName: string;
  slug: string;
}

export interface SuggestionQuestionType {
  id: number;
  content: string;
}

export interface SuggestionAccountingFirmType {
  id: number;
  name: string;
  slug: string;
}

export interface SuggestionCompanyType {
  id: number;
  name: string;
  slug: string;
}

export interface SuggestionApeCodeType {
  id: number;
  code: string;
  legend: string;
}

export interface SuggestionQuestionCategoryType {
  id: number;
  title: string;
}

export type SuggestionType =
  SuggestionAccountingFirmType
  | SuggestionQuestionType
  | SuggestionAccountType
  | SuggestionUserType
  | SuggestionCompanyType
  | SuggestionQuestionCategoryType;

type Props = {
  suggestions: SuggestionType[];
  setSuggestionSelected: (arg: SuggestionType) => void;
  setSuggestionsIsOpen: (arg: boolean) => void;
  entity: EntityType;
  search?: string;
};

export function SearchSuggestions({ suggestions, entity, setSuggestionSelected, setSuggestionsIsOpen, search = "" }: Props) {

  function getStr(suggestion: any) {
    switch (entity) {
      case "user":
        return suggestion?.firstName + " " + suggestion?.lastName;

      case "company":
        return suggestion?.name;

      case "accountingFirm":
        return suggestion?.name;

      case "account":
        return suggestion?.code + " - " + suggestion?.legend;

      case "apeCode":
        return suggestion?.code + " - " + suggestion?.legend;

      case "category":
        return suggestion?.title;

      default:
        return "";
    }
  }

  return (
    <div className="search-suggestions">
      {suggestions.map((suggestion: any, key) =>
        <div
          className="suggestion clickable"
          key={key}
          onClick={() => {
            setSuggestionSelected(suggestion);
            setSuggestionsIsOpen(false);
          }}
        >
          <SearchBold
            str={getStr(suggestion)}
            search={search}
          />

        </div>
      )}

      {suggestions.length === 0 && <div className="message">Pas de correspondance</div>}
    </div>
  );
}
