"use client";
import { HTMLAttributes } from "react";

import classNames from "classnames";
import { CgMathMinus, CgMathPlus } from "../../../react-icons/cg";
import { Tooltip } from "../../infos";
import { InputContainer } from "../InputContainer";

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: number;
  setter?: (arg: number) => void;
  direction?: "row" | "column";
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  id?: string;
  legend?: string;
  min?: number;
  max?: number;
  step?: number;
  decimals?: number;
}

export function NumberInput({ readonly = false, id, legend, min, max, step = 1, decimals = 1, value, setter, disabled = false, direction = "column", required = false, ...props }: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "numberInput";
  const handleSubstract = () => {
    let tempNewValue = value - step;
    if ((min === undefined || tempNewValue >= min) && disabled == false) {
      tempNewValue = parseFloat(tempNewValue?.toFixed(decimals));
      setter(tempNewValue);
    }
  };

  const handleAdd = (e) => {
    let tempNewValue = value + step;

    if ((max === undefined || tempNewValue <= max) && disabled == false) {
      tempNewValue = parseFloat(tempNewValue?.toFixed(decimals));
      setter(tempNewValue);
    }
  };

  const handleChange = (e) => {
    let temp = e.target.value;
    if (!disabled && !isNaN(temp)) {
      temp = parseFloat(temp);
      setter(temp);
    } else {
      setter(min);
    }
  };

  const handleBlur = (e) => {
    let temp = e.target.value;

    if (isNaN(temp) || e.target.value.length == 0) {
      if (value <= max && value >= min && !isNaN(value)) {
        temp = parseFloat(temp.toFixed(decimals));
        setter(temp);
        return;
      } else {
        setter(min);
      }

      return;
    }

    if (temp < min) {
      temp = min;
    }
    if (temp > max) {
      temp = max;
    }

    setter(parseFloat(temp.toFixed(decimals)));
  };

  return (
    <InputContainer {...props} direction={direction}>
      <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend ?? "number"} {required && "*"}
      </label>

      <div className={classNames("number-input", { disabled })}>
        <button aria-label="Diminuer" className={"minus"} disabled={disabled || (min && +value - 1 * step < min) || value <= min} onClick={handleSubstract}>
          <Tooltip legend={disabled || (min && +value - 1 * step < min) || value <= min ? "Le minimum est " + min : ""}>
            <CgMathMinus />
          </Tooltip>
        </button>

        <input
          disabled={!setter || disabled}
          readOnly={readonly}
          type="number"
          id={id}
          step={step}
          value={value}
          min={min}
          max={max}
          onBlur={(e) => handleBlur(e)}
          onChange={(e) => handleChange(e)}
        />

        <button aria-label="Augmenter" className={"plus"} disabled={disabled || (max && +value + 1 * step > max) || value >= max} onClick={handleAdd}>
          <Tooltip legend={disabled || (max && +value + 1 * step > max) || value >= max ? "Le maximum est " + max : ""}>
            <CgMathPlus />
          </Tooltip>
        </button>
      </div>
    </InputContainer>
  );
}
