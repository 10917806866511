"use client";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, A11y, Pagination } from "swiper";
import classNames from "classnames";

import type { JSX } from "react";

type Props = {
  steps: JSX.Element[] | string[];
  step: number;
  handleClick: (arg: number) => void;
};

export function MultiStepProgressBarMobile({ steps, step, handleClick }: Props) {

  return (
    <Swiper
      modules={[Navigation, A11y, Pagination]}
      pagination={{ type: "custom" }}
      navigation={true}
      slidesPerView={"auto"}
      centeredSlides={true}
      className="tabs-container"
      onSlideChange={(e) => {
        handleClick(e.activeIndex);
      }}
      initialSlide={step}
    >
      {steps?.map((field, key) => (
        <SwiperSlide key={key}>
          <div
            key={key}
            className={classNames(step === key ? "item current" : "item clickable", "tab-item")}
            onClick={() => handleClick(key)}
          >
            <div className="circle">{key + 1}</div>

            <p className="legend">{field}</p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
