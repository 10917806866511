"use client";

import { useEffect, useState, type JSX } from "react";
import { BtnContainer, Button } from "./../../../components/buttons";
import { formatChildrenToArray, formatChildrenToJSX, formatWithKey } from "./formatChildren";

type Props = {
  children: JSX.Element[] | JSX.Element;
  buttonSize?: "medium" | "big" | "small";
  iconSend?: "check" | "send" | "edit" | "save" | "add";
  iconCancel?: "close" | "trash" | "return";
  handleSend?: (arg?: any) => void | Promise<any>;
  handleCancel?: () => void | Promise<any>;
  legendSend?: string;
  legendCancel?: string;
};

export default function SingleStepForm({
  children,
  handleSend,
  handleCancel,
  legendSend = "Valider",
  iconSend,
  iconCancel,
  legendCancel = "Annuler",
  buttonSize = "medium",
}: Props) {
  const [childrenArray, setChildrenArray] = useState<any[]>(formatChildrenToArray(children));

  const [errors, setErrors] = useState<{ id: string; value: string; }[]>(formatWithKey(childrenArray, "error"));
  const [hasError, setHasError] = useState<boolean>(false);

  const [computedData, setComputedData] = useState<{ id: string; value: any; required: boolean; }[]>(formatWithKey(childrenArray, "value"));

  const handlePreSend = async () => {
    if (handleSend && !hasError) {
      // Transform computedData to Object to facilitate fetching
      let transformation = new Map();
      computedData.forEach((i, index) => transformation.set(i?.id, i?.value));

      return handleSend && handleSend(Object.fromEntries(transformation));
    }
  };

  const setError = (data: string, index: number) => {
    childrenArray[index]?.props?.setError && childrenArray[index]?.props?.setError(data);
    setErrors((prev) => [...prev.slice(0, index), { ...prev[index], value: data }, ...prev.slice(index + 1)]);
  };

  const setValue = (data: any, index: number) => {
    childrenArray[index]?.props?.setter && childrenArray[index].props.setter(data);
    setComputedData((prev) => [...prev.slice(0, index), { ...prev[index], value: data }, ...prev.slice(index + 1)]);
  };

  const onKeyPress = async (event: any) => {
    if (event.key === "Enter") {
      return handlePreSend();
    }
  };

  useEffect(() => {
    setHasError(errors.some((i) => i.value !== "" && i.value !== undefined));

    return () => {
      setHasError(false);
    };
  }, [errors]);

  const childrenUpdate = () => {
    const updateChildren = formatChildrenToArray(children);

    setChildrenArray(updateChildren);
    setErrors(prev => formatWithKey(updateChildren, "error", prev));
    setComputedData(prev => formatWithKey(updateChildren, "value", prev));
  };

  const reset = () => {
    const updateChildren = formatChildrenToArray(children);

    setChildrenArray(updateChildren);
    setErrors(formatWithKey(updateChildren, "error"));
    setComputedData(formatWithKey(updateChildren, "value"));

    childrenUpdate();
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyPress);
    childrenUpdate();

    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [children]);

  return (
    <>
      {hasError && <div className="error-message">{errors?.find((i) => i?.value !== "" && i?.value !== undefined)?.value}</div>}

      {formatChildrenToJSX(children, errors, setError, computedData, setValue)}

      <BtnContainer>
        {handleCancel && (
          <Button
            data-test="cancel"
            icon={iconCancel}
            type="transparent"
            size={buttonSize}
            cb={async () => {
              reset();
              return handleCancel();
            }}
          >
            {legendCancel}
          </Button>
        )}

        {handleSend && (
          <Button
            data-test="send"
            disabled={hasError || computedData
              .filter(i => i?.required)
              .some((i) => i.value === "" || i.value === undefined || i.value == null)}
            icon={iconSend}
            type="primary"
            size={buttonSize}
            cb={handlePreSend}
            tooltip={errors?.flat()?.find((i) => i?.value !== "" && i?.value !== undefined)?.value}
          >
            {legendSend}
          </Button>
        )}
      </BtnContainer>
    </>
  );
}
