import classNames from "classnames";
import { BsArrowDown, BsArrowUp } from "../../../react-icons/bs";

type Props = {
  sortFieldName: string;
  setSortFieldName: (arg: string) => void;
  sortFieldDirection: "desc" | "asc";
  setSortFieldDirection: (arg: "desc" | "asc") => void;
  items: Array<{ value?: string; legend: string; position?: "text-left" | "text-center" | "text-right"; }>;
};

export function TableHeader({
  sortFieldName,
  setSortFieldName,
  sortFieldDirection,
  setSortFieldDirection,
  items,
}: Props) {
  return (
    <thead>
      <tr>
        {items.map((item, index) => (
          <TableItem
            key={index}
            value={item.value}
            legend={item.legend}
            sortFieldDirection={sortFieldDirection}
            setSortFieldDirection={setSortFieldDirection}
            sortFieldName={sortFieldName}
            setSortFieldName={setSortFieldName}
            position={item.position}
          />
        ))}
      </tr>
    </thead>
  );
}

type ItemProps = {
  legend: string;
  value?: string;
  sortFieldName: string;
  setSortFieldName: (arg: string) => void;
  sortFieldDirection: "desc" | "asc";
  setSortFieldDirection: (arg: "desc" | "asc") => void;
  position?: "text-left" | "text-center" | "text-right";
};

function TableItem({
  legend,
  value,
  sortFieldName,
  setSortFieldName,
  sortFieldDirection,
  setSortFieldDirection,
  position = "text-left",
}: ItemProps) {
  function handleSortFieldName(fieldName: string) {
    sortFieldName === fieldName ? handleSortDirection() : setSortFieldDirection("asc");

    setSortFieldName(fieldName);
  }

  function handleSortDirection() {
    sortFieldDirection === "desc" && setSortFieldDirection("asc");
    sortFieldDirection === "asc" && setSortFieldDirection("desc");
  }

  return (
    <th
      aria-hidden={!legend}
      datatype={legend}
      className={classNames(value && "clickable", position)}
      onClick={value ? () => handleSortFieldName(value) : () => { }}
    >
      {legend}
      {sortFieldName === value && (sortFieldDirection === "desc" ? <BsArrowUp /> : <BsArrowDown />)}
    </th>
  );
}
