import classNames from "classnames";
import { useState } from "react";

type Props = {
  onClick: (startDate: Date, endDate: Date) => void;
  startDate: Date | null;
  endDate: Date | null;
};

const CalendarWrapperRapidSelectAside = ({ onClick, startDate, endDate }: Props) => {
  const [today, setToday] = useState(null);
  const [yesterday, setYersterday] = useState(null);
  const [thisWeek, setThisWeek] = useState(null);
  const [lastWeek, setLastWeek] = useState(null);
  const [thisMonth, setThisMonth] = useState(null);
  const [lastMonth, setLastMonth] = useState(null);
  const [thisYear, setThisYear] = useState(null);
  const [lastYear, setLastYear] = useState(null);

  const getFirstDayOfWeek = () => {
    const today = new Date(); // Date actuelle
    const dayOfWeek = today.getDay(); // Jour de la semaine (0 = dimanche, 1 = lundi, etc.)
    const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Combien de jours retourner pour atteindre lundi
    const monday = new Date(today); // Copie de la date actuelle
    monday.setDate(today.getDate() - daysToMonday); // Calcul du lundi
    return monday.getDate(); // Retourne le jour du mois
  };

  const handleClick = (date) => {
    const todayDate = new Date();

    let startDate, endDate;

    switch (date) {
      case "today":
        startDate = new Date(todayDate);
        endDate = new Date(todayDate);
        setToday([startDate, endDate]);
        break;
      case "yesterday":
        startDate = new Date(todayDate);
        startDate.setDate(todayDate.getDate() - 1);
        endDate = new Date(startDate);
        setYersterday([startDate, endDate]);
        break;
      case "this-week":
        startDate = new Date(todayDate);
        startDate.setDate(getFirstDayOfWeek()); // Dimanche
        endDate = new Date(todayDate);
        endDate.setDate(getFirstDayOfWeek() + 4); // Samedi
        setThisWeek([startDate, endDate]);
        break;
      case "last-week":
        const dayOfWeek = todayDate.getDay();
        const dayOfMonth = new Date(todayDate).getDate();
        const lastFriday = new Date(todayDate);
        lastFriday.setDate(dayOfMonth - dayOfWeek - 2);
        endDate = lastFriday;
        const lastMonday = new Date(todayDate);
        lastMonday.setDate(lastFriday.getDate() - 4);
        startDate = lastMonday;
        setLastWeek([startDate, endDate]);

        break;
      case "this-month":
        startDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1); // Premier jour du mois
        endDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0); // Dernier jour du mois
        setThisMonth([startDate, endDate]);
        break;
      case "last-month":
        startDate = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1); // Premier jour du mois précédent
        endDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0); // Dernier jour du mois précédent
        setLastMonth([startDate, endDate]);
        break;
      case "this-year":
        startDate = new Date(todayDate.getFullYear(), 0, 1); // Premier jour de l'année
        endDate = new Date(todayDate.getFullYear(), 11, 31); // Dernier jour de l'année
        setThisYear([startDate, endDate]);
        break;
      case "last-year":
        startDate = new Date(todayDate.getFullYear() - 1, 0, 1); // Premier jour de l'année précédente
        endDate = new Date(todayDate.getFullYear() - 1, 11, 31); // Dernier jour de l'année précédente
        setLastYear([startDate, endDate]);
        break;
    }

    onClick(startDate, endDate);
  };

  return (
    <div className="column pr-2 w-100 h-100 rapidSelectAside ">
      <div className="column tile gray-light w-100 pl-1">
        <p className={classNames("choice", { selected: startDate === today?.[0] && endDate === today?.[1] })} onClick={() => handleClick("today")}>
          Aujourd'hui
        </p>
        <p className="choice" onClick={() => handleClick("yesterday")}>
          Hier
        </p>
        <p className="choice" onClick={() => handleClick("this-week")}>
          Cette semaine
        </p>
        <p className="choice" onClick={() => handleClick("last-week")}>
          La semaine dernière
        </p>
        <p className="choice" onClick={() => handleClick("this-month")}>
          Ce mois-ci
        </p>
        <p className="choice" onClick={() => handleClick("last-month")}>
          Le mois dernier
        </p>
        <p className="choice" onClick={() => handleClick("this-year")}>
          Cette année
        </p>
        <p className="choice" onClick={() => handleClick("last-year")}>
          L'année dernière
        </p>
      </div>
    </div>
  );
};

export default CalendarWrapperRapidSelectAside;
