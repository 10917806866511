"use client";
import { HtmlHTMLAttributes, useEffect, useRef, useState, type JSX } from "react";
import MultiStepForm from "./MultiStepForm";
import SingleStepForm from "./SingleStepForm";

interface Props extends HtmlHTMLAttributes<HTMLFormElement> {
  children: JSX.Element[] | JSX.Element;
  buttonSize?: "medium" | "big" | "small";
  iconSend?: "check" | "send" | "edit" | "save" | "add";
  iconCancel?: "close" | "trash" | "return";
  handleSend?: (arg?: any) => void | Promise<any>;
  handleCancel?: () => void | Promise<any>;
  legendSend?: string;
  legendCancel?: string;
  type?: "create" | "update";
}

export function Form({
  children,
  handleSend,
  handleCancel,
  legendSend = "Valider",
  iconSend,
  iconCancel,
  legendCancel = "Annuler",
  buttonSize = "medium",
  type = "create",
  ...props
}: Props) {
  const formRef = useRef(null);

  function childrenIsMultiStep(value: JSX.Element[] | JSX.Element) {
    const arr = (Array.isArray(value) ? value.filter((i) => i) : [value]).flat();

    return arr?.some((i) => i?.type?.name === "Step");
  }

  const [isMultiStep, setIsMultiStep] = useState(childrenIsMultiStep(children));

  useEffect(() => {
    setIsMultiStep(childrenIsMultiStep(children));
  }, [children]);

  return (
    <form {...props} onSubmit={(e) => e.preventDefault()} ref={formRef}>
      {isMultiStep ? (
        <>
          <MultiStepForm
            handleSend={handleSend}
            handleCancel={handleCancel}
            legendSend={legendSend}
            iconSend={iconSend}
            iconCancel={iconCancel}
            legendCancel={legendCancel}
            buttonSize={buttonSize}
            type={type}
          >
            {children}
          </MultiStepForm>
        </>
      ) : (
        <SingleStepForm
          handleSend={handleSend}
          handleCancel={handleCancel}
          legendSend={legendSend}
          iconSend={iconSend}
          iconCancel={iconCancel}
          legendCancel={legendCancel}
          buttonSize={buttonSize}
        >
          {children}
        </SingleStepForm>
      )}
    </form>
  );
}
