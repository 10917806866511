"use client";
import classNames from "classnames";
import { HTMLAttributes, type JSX } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  showError?: boolean;
  errorMessage?: string;
  children: JSX.Element | JSX.Element[];
  direction?: "column" | "row" | "row-reverse" | "column-reverse";
}

export function InputContainer({ direction = "column", children, errorMessage, showError, ...props }: Props) {
  return (
    <div style={{ width: props?.style?.width ?? "100%" }}>
      {errorMessage && errorMessage?.length !== 0 && showError && <div className="error-message">{errorMessage}</div>}
      <div {...props} className={classNames(props?.className, "input-container", direction)}>
        {children}
      </div>
    </div>
  );
}
