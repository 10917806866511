import { type JSX } from "react";

type Props = {
  steps: JSX.Element[] | string[];
  step: number;
  handleClick: (arg: number) => void;
};

export function MultiStepProgressBarDesktop({ steps, step, handleClick }: Props) {
  return (
    <>
      <div className="tile pt-0 br-5 mb-05 pb-0 pt-1 items" style={{ gridTemplateColumns: `repeat(${steps?.length}, 1fr)` }}>
        {steps?.map((field, key: number) => {
          return (
            <div key={key} className={step == key ? "item current" : "item clickable"} onClick={() => handleClick(key)}>
              <div className="circle">{key + 1}</div>

              <p className="legend">{field}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}
