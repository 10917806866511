"use client";

import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components/blocs";
import { Button } from "../../../components/buttons";
import { CircleLoader } from "../../../components/loaders";

interface Props extends HTMLAttributes<HTMLInputElement> {
  length?: number;
  legend?: string;
  value?: string;
  isLoading?: boolean;
  required?: boolean;
  disabled?: boolean;
  alertCb?: () => void;
  setter: (param: any) => void;
}

export function CodeInput({ length = 6, setter, legend, isLoading, value, required, alertCb, disabled = false, ...props }: Props) {
  const [inputs, setInputs] = useState([]);
  const refs = useRef([]);

  const handlePast = async (e) => {
    e.preventDefault();
    // Get pasted data via clipboard API
    const text = await navigator.clipboard.readText();

    let parsed = parseInt(text).toString();
    if (parsed.length == length) {
      setInputs(parsed?.split(""));
      handlePersist(parsed?.split(""));
    } else {
      alertCb();
    }
  };

  const handleChangeValue = (key, newValue) => {
    let temp = JSON.parse(JSON.stringify(inputs));
    /// Si c'est un nombre, et qu'il y en a qu'un, on peut parler
    if (parseInt(newValue)?.toString()?.length == 1) {
      temp[key] = newValue;
      if (key + 1 == length) {
        refs?.current?.[0]?.focus();
      } else {
        refs?.current?.[key + 1]?.focus();
      }
    } else {
      // sinon, c'est niet, reset

      temp[key] = "";
    }
    // handlePersist(temp);
    setInputs(temp);
  };

  const initInputs = () => {
    const temp = Array.from(Array(length)).map((item) => "");
    setInputs(temp);
    setter(temp.join(""));
  };

  useEffect(() => {
    initInputs();
  }, [length]);

  const handlePersist = (finalValues) => {
    if (finalValues.every((item) => item.length == 1 && parseInt(item).toString().length == 1)) {
      setter(finalValues.join(""));
    }
  };

  /// reset (moche pas le choix)
  useEffect(() => {
    if (value == "") {
      initInputs();
    }
  }, [value]);

  // 123456
  // 383838
  // 222222
  // 383f38

  return (
    <div className="w-100 m-auto p-2" {...props}>
      {isLoading ? (
        <CircleLoader width="40" strokeWidth={8} />
      ) : (
        <>
          <h4>{legend}</h4>
          <Grid template={"repeat(" + length + ", 1fr)"} space={4}>
            {inputs?.map((input, key) => {
              return (
                <input
                  key={key}
                  value={input}
                  ref={(el) => {
                    refs.current[key] = el;
                  }} // Associer la ref
                  type="text"
                  onPaste={(e) => handlePast(e)}
                  onFocus={(e) => e.target.select()}
                  onChange={() => {
                    // delete eror console
                  }}
                  disabled={setter && disabled}
                  className="fw-700 fs-140"
                  onKeyDown={(e) => {
                    handleChangeValue(key, e.key);
                  }}
                  style={{ width: "100%", height: "80px", textAlign: "center" }}
                />
              );
            })}
          </Grid>
          <Button
            className="mt-3 mb-0"
            icon={"check"}
            type="primary"
            form="circle"
            cb={() => handlePersist(inputs)}
            disabled={!inputs?.every((item) => parseInt(item).toString().length == 1)}
          >
            Confirmer
          </Button>
        </>
      )}
    </div>
  );
}
