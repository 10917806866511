"use client";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperProps } from "swiper/react";
import classNames from "classnames";

interface Props extends SwiperProps {
  children: any;
  className?: string;
  onSlideChange?: (arg: any) => void;
  onSlideChangePercentCb?: (arg: any) => void;
};

export function SwiperComponent({
  children,
  className,
  slidesPerView = "auto",
  navigation = true,
  spaceBetween = 10,
  initialSlide = 0,
  onSlideChange,
  onSlideChangePercentCb,
  centeredSlides = true,
  slideToClickedSlide = true,
  ...props
}: Props) {

  const handleChanges = (e) => {
    if (onSlideChangePercentCb) {
      onSlideChangePercentCb(e.progress);
    }
    if (onSlideChange) {
      onSlideChange(e.realIndex);
    }
  };

  return (
    <Swiper
      {...props}
      id="swiper-container"
      className={classNames(className, "mySwiper")}
      modules={[Navigation, A11y]}
      navigation={navigation}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      initialSlide={initialSlide}
      centeredSlides={centeredSlides}
      slideToClickedSlide={slideToClickedSlide}
      onSlideChange={(e) => handleChanges(e)}
    >
      {children}
    </Swiper>
  );
}
