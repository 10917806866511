import { currencies } from "../../../../utilities/config/constants";
import { OptionInput } from "../selectInput/OptionInput";
import { SelectInput } from "../selectInput/SelectInput";

type Props = {
  value: string;
  setter: (arg: string) => void;
};

export function SelectInputCurrency({ value, setter }: Props) {
  return (
    <SelectInput
      setter={(e) => {
        setter(e);
      }}
      legend={"Devise par défaut"}
      placeholder={"Euros si non renseigné"}
      value={value ?? "EUR"}
      id={"defaultCurrencyCode"}
    >
      {currencies?.map((currency, key) => {
        return <OptionInput value={currency.value} legend={currency.legend} key={key} />;
      })}
    </SelectInput>
  );
}
