import classNames from "classnames";
import { HTMLAttributes, type JSX } from "react";
import { useMediaQuery } from "../../../../../utilities/hooks/useMediaQuery";
import { MultiStepProgressBarDesktop } from "./MultiStepProgressBarDesktop";
import { MultiStepProgressBarMobile } from "./MultiStepProgressBarMobile";

interface Props extends HTMLAttributes<HTMLDivElement> {
  step: number;
  steps: JSX.Element[] | string[];
  handleClick: (arg: number) => void;
  type?: "white" | "gray-light" | "gray" | "empty";
}

export function MultiStepProgressBar({ step, steps, handleClick, type = "white", ...props }: Props) {
  const mediaQuery = useMediaQuery(500);

  return (
    <div
      {...props}
      id="progress-bar-container"
      className={classNames(
        props.className,
        type,
        {
          desktop: !mediaQuery,
          "tab-wrapper mobile": mediaQuery,
        },
        props.className
      )}
    >
      {mediaQuery && <MultiStepProgressBarMobile steps={steps} step={step} handleClick={handleClick} />}

      {!mediaQuery && <MultiStepProgressBarDesktop steps={steps} step={step} handleClick={handleClick} />}
    </div>
  );
}
