"use client";

import classNames from "classnames";
import { HTMLAttributes, useEffect, useState } from "react";
import { Badge } from "../../badges";
import { IconButton } from "../../buttons";
import { IconButtonIcons, IconList } from "../../icons";
import { InfoBulle, Tooltip } from "../../infos";
import { InputContainer } from "../InputContainer";

interface Props extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  legend?: string;
  value?: string;
  setter?: (arg: string) => void;
  direction?: "column" | "row";

  // ERROR
  setError?: (error: string) => void;
  showError?: boolean;
}

export function RegexInput({
  id,
  required = false,
  disabled = false,
  placeholder = "Exemple: 700",
  direction = "column",
  legend,
  value = "",
  setter,
  setError,
  showError = false,
  ...props
}: Props) {
  const PATTERN = new RegExp("{[^}]+}|[-+*/()d]", "g");
  const CODE_PATTERN = new RegExp("{[^}]+}", "g");
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "textInput";
  const [errorMessage, setErrorMessage] = useState("");
  const [valueArray, setValueArray] = useState([]);

  // Check if value is correct
  function error(arr: string[]) {
    if (arr.length > 0 || required) {
      let errorComputed = "";

      if (arr.length === 0) {
        errorComputed = "Le champ codes de la courbe ne peut pas être vide";
      } else if (arr[arr.length - 1].match(CODE_PATTERN)) {
        // errorComputed = "La valeur entrée n'est pas un nombre";
      } else {
        errorComputed = "Vous ne pouvez pas terminer les codes de la courbe avec une opération";
      }
      setError && setError(errorComputed);

      return errorComputed;
    }
    return "";
  }

  function operationIcon(operation: string): IconList {
    switch (operation) {
      case "+":
        return "plus2";
      case "-":
        return "minus2";
      case "/":
        return "divide";
      case "*":
        return "times";
    }
  }

  function removeValue(index: number) {
    setValueArray((prev) => {
      let newArray: string[];

      if (prev[index + 1]) {
        newArray = [...prev.slice(0, index), ...prev.slice(index + 2)];
      } else {
        newArray = newArray = [...prev.slice(0, index), ...prev.slice(index + 1)];
      }

      setErrorMessage(error(newArray));
      setter(newArray.join(""));
      return newArray;
    });
  }

  const handleAddCode = (index: number, value: string) => {
    if (value) {
      const computedValue = `{${parseInt(value)}}`;

      setValueArray((prev) => {
        let newArray: string[];

        if (index) {
          newArray = [...prev.slice(0, index), computedValue, ...prev.slice(index + 1)];
        } else {
          newArray = [...prev, computedValue];
        }

        setErrorMessage(error(newArray));
        setter(newArray.join(""));
        return newArray;
      });
    }
  };

  const handleAddOperation = (index: number, operation: string) => {
    setValueArray((prev) => {
      let newArray;

      if (index) {
        newArray = [...prev.slice(0, index), operation, ...prev.slice(index + 1)];
      } else {
        newArray = [...prev, operation];
      }
      setErrorMessage(error(newArray));
      return newArray;
    });
  };

  useEffect(() => {
    const newArray = Array.from(value.match(PATTERN) ?? []);
    setErrorMessage(error(newArray));
    setValueArray(newArray);
  }, [value]);

  useEffect(() => {
    const newArray = Array.from(value.match(PATTERN) ?? []);
    setErrorMessage(error(newArray));
    setValueArray(newArray);
  }, []);

  return (
    <InputContainer {...props} className={props.className} direction={direction}>
      <label className="row a-center" hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend ?? "text"} {required && "*"}
        <InfoBulle>
          <p className="text-left">Vous pouvez sélectionnez et faire des opérations sur les comptes choisis</p>
          <br />
          <p className="text-left">
            <u>Exemple:</u> <em>7 + 6</em> vous permettra d'avoir une courbe qui additionne les comptes commençant par <em>7</em> et <em>6</em>
          </p>
        </InfoBulle>
      </label>

      {errorMessage && errorMessage?.length !== 0 && showError && <div className="error-message">{errorMessage}</div>}

      <div className={classNames("tile thin mt-0")}>
        {valueArray?.length > 0 && (
          <div className="row a-center">
            {valueArray?.length > 0 &&
              valueArray?.map((value, key: number) => (
                <Tooltip legend={value?.match(CODE_PATTERN) && `Compte commençant par ${value?.replaceAll("{", "")?.replaceAll("}", "")}…`} position="bottom" key={`value_${key}`}>
                  <Badge className="row pt-05 pb-05 pr-05 gap-1" size="big">
                    {value?.match(CODE_PATTERN) ? value?.replaceAll("{", "")?.replaceAll("}", "") : <IconButtonIcons size={15} icon={operationIcon(value)} />}

                    <IconButton form="circle" icon="close" cb={() => removeValue(key)} />
                  </Badge>
                </Tooltip>
              ))}
          </div>
        )}

        <div className="input-container column tile thin gray-light">
          {valueArray?.length > 0 && valueArray[valueArray?.length - 1]?.match(CODE_PATTERN) ? (
            <>
              <label>Sélectionnez une opération</label>
              <div className="row">
                {["+", "-", "*", "/"].map((operation) => (
                  <IconButton key={`operation_${operationIcon(operation)}`} type="transparent" cb={() => handleAddOperation(null, operation)} icon={operationIcon(operation)} />
                ))}
              </div>
            </>
          ) : (
            <>
              <label>Le code des comptes commençant par&#8230;</label>

              <form
                className="row a-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddCode(null, e.target[0].value);
                }}
              >
                <input
                  aria-labelledby={`${NAMESPACE}_label`}
                  id={NAMESPACE}
                  data-errormessage={errorMessage}
                  placeholder={placeholder}
                  required={required}
                  disabled={!setter || disabled}
                  onBlur={(e) => {
                    handleAddCode(null, e.target.value);
                  }}
                />

                <button className="icon-button primary clickable square" type="submit">
                  <IconButtonIcons icon="check" />
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </InputContainer>
  );
}
