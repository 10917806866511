'use client';
import classNames from 'classnames';
import { HTMLAttributes, useEffect, useState } from 'react';
import { Tooltip } from 'ui/components/infos';
import { CompanyApeCodeType } from '../../../../utilities/redux/types';
import { IconButton } from '../../../components/buttons';
import { InputContainer } from '../InputContainer';
import { CriteriaType, SearchInput } from '../searchInput/SearchInput';
import { SuggestionType } from '../searchInput/parts/SearchSuggestions';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  legend?: string;
  value?: CompanyApeCodeType | null;
  setter?: (arg: CompanyApeCodeType | null) => void;
  direction?: "column" | "row";
  type?: "short" | "long";

  // Search
  handleSearch: (search: string, signal: AbortSignal, criteria?: CriteriaType) => Promise<SuggestionType[]> | void;
  isLoading?: boolean;

  // ERROR
  showError?: boolean;
  setError?: (error: string) => void;
}

export function ApeCodeInput({
  className,
  handleSearch,
  isLoading,
  id,
  required = false,
  disabled = false,
  placeholder,
  direction = "column",
  legend,
  value,
  setter,
  setError,
  showError = false,
  type = "long",
  ...props }: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "textInput";
  const [errorMessage, setErrorMessage] = useState("");

  // Check if value is correct
  function error(targetValue: string) {
    if (required && !targetValue) {
      const errorComputed = "Vous devez choisir un code APE";

      setError && setError(errorComputed);

      return errorComputed;
    }
    return "";
  }

  const handleChange = (e) => {
    setErrorMessage(error(e?.code));
    setter && setter(e);
  };

  useEffect(() => {
    value?.code && setErrorMessage(error(value?.code));
  }, [value?.code]);

  return (
    <InputContainer {...props} className={classNames("codeApe-input", className)} errorMessage={errorMessage} showError={showError} direction={direction}>
      {legend
        ? <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
          {legend} {required && "*"}
        </label>
        : <></>}

      {disabled
        ? (type === "long"
          ? <div className='m-0 tile gray-light row a-center'>
            {value?.code
              ? `${value?.code} - ${value?.legend}`
              : "Aucun code APE renseigné"}
          </div>
          : <Tooltip legend={value?.legend ?? ""} className='m-0 tile pt-05 pb-05 gray-light row a-center w-100'>
            {value?.code
              ? `${value?.code}`
              : "Aucun code APE renseigné"}
          </Tooltip>)
        : (value?.code
          ? (type === "long"
            ? <div className='m-0 tile gray-light row a-center space-between'>
              {value?.code} - {value?.legend}

              {!isLoading && setter && <IconButton size={18} form='circle' icon='close' cb={() => setter(null)} type='empty' />}
            </div>
            : <Tooltip legend={value?.legend ?? ""} className='m-0 tile pt-05 pb-05 gray-light row a-center space-between w-100'>
              <>
                {value?.code}

                {!isLoading && setter && <IconButton size={18} form='circle' icon='close' cb={() => setter(null)} type='empty' />}
              </>
            </Tooltip>)


          : <SearchInput
            entity='apeCode'
            placeholder={placeholder}
            aria-labelledby={`${NAMESPACE}_label`}
            id={NAMESPACE}
            isLoading={isLoading}
            handleSearch={handleSearch}
            setSuggestionSelected={handleChange}
          />)}

      { }
    </InputContainer>
  );
}