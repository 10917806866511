'use client';

import { useEffect, useState } from "react";
import { PasswordInput } from "..";
import { AiFillEye, AiFillEyeInvisible } from "../../../react-icons/ai";
import { BsCheckLg, BsXLg } from "../../../react-icons/bs";
import { Tooltip } from "../../infos";

type Props = {
  id?: string;
  direction?: "column" | "row";
  registerMode?: boolean;
  value: string;
  setter?: (arg: string) => void;
  legend?: string;
  placeholder?: string;
  setIsValid?: (arg: boolean) => void;
  error?: string;
  setError?: (arg: string) => void;
};


export function ConfirmationPasswordInput({
  id,
  direction = "column",
  registerMode = true,
  value,
  setter,
  legend,
  placeholder,
  error,
  setError,
}: Props) {
  const [isSecret, setIsSecret] = useState<boolean>(true);
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (value: any) => {
    setter && setter(value.trim());
    let error = "";
    if (passwordError?.length === 0) {
      error = value.trim() !== password.trim() ? "Les mots de passe ne sont pas identiques" : "";
    } else {
      error = passwordError;
    }
    setError && setError(error);
  };

  useEffect(() => {
    error = passwordError?.length > 0
      ? passwordError
      : ((value.trim() !== password.trim())
        ? "Les mots de passe ne sont pas identiques"
        : "");
    setError && setError(error);
  }, [password]);

  return (
    <div id={id}>
      <PasswordInput
        data-test="password"
        registerMode={true}
        value={password}
        setter={setPassword}
        required
        setError={setPasswordError}
        placeholder={placeholder}
        legend={legend}
      />

      <div className={direction === "column" ? "input-container column" : "input-container"}>
        <label htmlFor={"password-confirmation"}>Confirmation mot de passe *</label>
        <div className="password-container">
          <div className="password-valid">
            <input
              data-test="confirmation-password"
              type={isSecret ? "password" : "text"}
              id={legend}
              value={value}
              onChange={(e: any) => handleChange(e.target.value)}
              autoComplete="on"
              required={true}
              placeholder={"Entrez de nouveau le mot de passe"}
            />
            {isSecret ? (
              <AiFillEye className="eye clickable" onClick={() => setIsSecret(false)} size={25} />
            ) : (
              <AiFillEyeInvisible className="eye clickable" onClick={() => setIsSecret(true)} size={25} />
            )}

            {registerMode && value?.length !== 0 && (
              <div className="confirmation">
                <Tooltip legend={error || ""}>
                  {error ? (
                    <BsXLg className="unvalid" size={20} />
                  ) : (
                    <BsCheckLg className="valid" size={20} />
                  )}
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}