export function formatStepsToArray(value: JSX.Element[] | JSX.Element): Array<any> {
  const arr = (Array.isArray(value) ? value.map((i) => (i?.props?.legend ? i : undefined)) : [value?.props?.legend ? value : undefined]).filter((i) => i);

  return arr;
}

export function formatWithKey(array: any[], x: string, prevValues?: { id: string; value: any; required?: boolean; }[]) {

  return array.flat(Infinity).map((child) => {
    const prevValue = prevValues?.find((i) => i?.id === child?.props?.id)?.value;

    return { id: child?.props?.id, value: prevValue ?? child?.props?.[x], required: child?.props?.registerMode ?? child?.props?.required };
  });
}

export function formatChildrenToArray(value: JSX.Element[] | JSX.Element): any[] {
  const arr = Array.isArray(value) ? value.filter((i) => i) : [value];

  if (arr.some((i) => i?.props?.children)) {
    return arr
      .map((child) => {
        return child?.props?.children && child?.props?.value === undefined ? formatChildrenToArray(child.props.children) : [child];
      })
      .flat(Infinity);
  } else {
    return arr.flat(Infinity);
  }
}

export function formatChildrenToJSX(
  element: JSX.Element | JSX.Element[],
  errors: { id: string; value: string; }[],
  setError: (data: string, index: number) => void,
  values: { id: string; value: any; }[],
  setValue: (data: any, index: number) => void
): JSX.Element[] {
  const array = Array.isArray(element) ? element.filter((i) => i) : [element];

  return array?.map((child: JSX.Element) => {
    const index = values?.findIndex((i) => i?.id && i?.id === child?.props?.id);

    // INPUTS
    if (index !== -1) {
      return {
        ...child,
        props: {
          ...child?.props,

          // ERROR
          error: errors[index].value,
          setError: (data: string) => setError(data, index),

          // VALUE
          value: values[index].value,
          setter: (data: any) => setValue(data, index),
        },
      };
    }
    // NOT INPUTS
    else {
      if (typeof child?.props?.children !== "string" && child?.props?.children && child?.props?.value === undefined) {
        return {
          ...child,
          props: {
            ...child?.props,
            children: formatChildrenToJSX(child?.props?.children, errors, setError, values, setValue),
          },
        };
      } else {
        return child;
      }
    }
  });
}

export function formatMultistepToJSX(
  element: JSX.Element | JSX.Element[],
  stepLegend: string,
  errors: { id: string; value: string; }[],
  setError: (data: string, index: number) => void,
  values: { id: string; value: any; }[],
  setValue: (data: any, index: number) => void
) {
  const array = Array.isArray(element) ? element.filter((i) => i) : [element];

  return array?.map((child) => {
    if (child?.props?.legend) {
      if (child?.props?.legend === stepLegend) return formatChildrenToJSX(child?.props?.children, errors, setError, values, setValue);
    } else {
      return child;
    }
  });
}
