"use client";
import classNames from "classnames";
import { HTMLAttributes, useEffect, useState } from "react";
import { InputContainer } from "../InputContainer";

interface Props extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  value: boolean;
  setter?: (arg: boolean) => void;
  size?: "small" | "normal" | "big";
  legend?: string;
  disabled?: boolean;
  required?: boolean;
  direction?: "row" | "column" | "row-reverse";

  //ERROR
  setError?: (error: string) => void;
  showError?: boolean;
}

export function ToggleInput({ id, value, setter, size = "normal", legend, disabled = false, required = false, direction = "row-reverse", setError, showError = false, ...props }: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "toggle";
  const [errorMessage, setErrorMessage] = useState("");

  // Check if value is correct
  function error(targetValue: boolean) {
    if (required) {
      let errorComputed = targetValue ? "" : `Le sélecteur ${(legend ?? id ?? "").toLowerCase()} doit être actif`;
      setError && setError(errorComputed);

      return errorComputed;
    }
    return "";
  }

  const handleChange = () => {
    if (!disabled && setter) {
      setErrorMessage(error(!value));
      setter(!value);
    }
  };

  useEffect(() => {
    setErrorMessage(error(value));
  }, [value]);

  return (
    <InputContainer
      {...props}
      errorMessage={errorMessage}
      showError={showError}
      className={classNames("toggle-item", size, props.className, { disabled: !setter || disabled })}
      direction={direction}
    >
      <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend ?? "toggle"} {required && "*"}
      </label>

      <div role="checkbox" className="switch" aria-labelledby={`${NAMESPACE}_label`} onClick={handleChange}>
        <input
          aria-hidden
          tabIndex={0}
          aria-labelledby={`${NAMESPACE}_label`}
          type="checkbox"
          disabled={!setter || disabled}
          id={NAMESPACE}
          checked={value}
          onChange={handleChange}
        />
        <span className="slider" />
      </div>
    </InputContainer>
  );
}
