"use client";
import classNames from "classnames";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components/blocs";
import { InputContainer } from "../InputContainer";

type Props = {
  legend?: string;
  min?: string;
  max?: string;
  value: string;
  id?: string;
  showLabel?: boolean;
  setter?: (arg: string) => void;
  className?: string;
  style?: CSSProperties;
  mustUpdate?: any;
  disabled?: boolean;
  mediaQuery?: number;
};

export function DateInput2({
  min = "2000-01-01",
  max = "2050-01-01",
  showLabel = false,
  value = null,
  setter,
  legend = "",
  className = "",
  style = {},
  id = "",
  mustUpdate = null,
  disabled = false,
  mediaQuery = 250
}: Props) {
  const [firstFocus, setFirstFocus] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  ///
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  ///
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  //
  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const handleUpdateFromValue = () => {
    if (value) {
      let tempYear = value.slice(0, 4);
      let tempMonth = value.slice(5, 7);
      let tempDay = value.slice(8, 10);

      setDay(tempDay);
      setMonth(tempMonth);
      setYear(tempYear);
    } else {
      setDay("");
      setMonth("");
      setYear("");
    }
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const getMaxDayInMonth = (directMonth) => {
    const dayInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    return dayInMonth[parseInt(directMonth) - 1] || 31; // Défaut à 31 si mois invalide
  };

  const onlyNumbers = (e) => e.target.value.replace(/\D/g, "");

  //    CHANGE EVENTS ///////////////////////////////////////

  const handleChangeDay = (e) => {
    let value = onlyNumbers(e);
    if (value.length > 2) {
      value = value.slice(0, 2);
    }

    /// max
    if (value > getMaxDayInMonth(month)) {
      value = getMaxDayInMonth(month)?.toString();
    }
    /// min
    if (value < 1 && value.length == 2) {
      value = "01";
    }

    setDay(value);

    if (value.length > 1) {
      monthRef?.current && monthRef?.current?.focus();
    }
    // computeDate(value, month, year);
  };

  const handleChangeMonth = (e) => {
    let value = onlyNumbers(e);

    if (value > 12) {
      value = "12";
    }

    if (value.length > 1) {
      if (value < 1) {
        value = "01";
      }

      yearRef?.current && yearRef?.current?.focus();
    }
    if (value.length > 2) {
      value = value.slice(0, 2);
    }
    setMonth(value);
    cbMonthChange(e.target.value);
    // computeDate(day, value, year);
  };

  const handleChangeYears = (e) => {
    let value = onlyNumbers(e);

    const maxYear = new Date(max)?.getFullYear();
    const minYear = new Date(min)?.getFullYear();

    if (value > maxYear && value.length >= 4) {
      value = maxYear;
    }
    if (value < minYear && value.length >= 4) {
      value = minYear;
    }

    setYear(value);
    // computeDate(day, month, value);
  };

  //    BLUR EVENTS ///////////////////////////////////////

  // -> DAYS
  const onBlurDay = (e) => {
    if (e.target.value.length == 1) {
      let temp = e.target.value.padStart(2, 0);
      setDay(temp);
    }
    checkOutOfRange();
    setIsFocus(false);
  };

  //-> MONTHS
  const cbMonthChange = (directMonth) => {
    // est appelée lorsque le mois change, vérif du jour
    let maxDayInMonth = getMaxDayInMonth(directMonth);

    if (parseInt(day) > maxDayInMonth) {
      setDay(maxDayInMonth.toString());
    }
  };

  const onBlurMonth = (e) => {
    if (e.target.value.length == 1) {
      let temp = e.target.value.padStart(2, 0);
      if (temp < 1) {
        temp = "1";
      }
      if (temp > 12) {
        temp = "12";
      }

      setMonth(temp);
      cbMonthChange(e.target.value);
      checkOutOfRange();
    }
    setIsFocus(false);
  };

  //-> YEAR
  const onBlurYear = (e) => {
    if (e.target.value.length == 2) {
      let temp = "20" + e.target.value;
      if (parseInt(temp) > maxYear) {
        temp = maxYear.toString();
      }
      if (parseInt(temp) < minYear) {
        temp = minYear.toString();
      }
      setYear(temp);
    }
    checkOutOfRange();
    setIsFocus(false);
  };

  ///// Check min and max Years
  /**
   * Check if current value is < min and > max and Reassign to the good value
   */
  const checkOutOfRange = () => {
    let minDate = new Date(min);
    let maxDate = new Date(max);

    if (new Date(year + "-" + month + "-" + day) < minDate) {
      setDay(minDate?.getDate()?.toString()?.padStart(2, "0"));
      setMonth((minDate?.getMonth() + 1).toString()?.padStart(2, "0"));
      setYear(minDate?.getFullYear()?.toString());
    }

    else if (new Date(year + "-" + month + "-" + day) > new Date(maxDate)) {
      setDay(maxDate?.getDate()?.toString()?.padStart(2, "0"));
      setMonth((maxDate?.getMonth() + 1).toString()?.padStart(2, "0"));
      setYear(maxDate?.getFullYear()?.toString());

    }
  };

  //    BLUR EVENTS ///////////////////////////////////////

  const handleBlurAll = (year, month, day) => {
    if (day < 1) {
      day = 1;
    }

    let computedDate = year?.toString() + "-" + month.toString()?.padStart(2, "0") + "-" + day?.toString()?.padStart(2, "0");
    setter(computedDate);
  };

  // const computeDate = (day, month, year) => {
  //   // let computedDate = new Date(year + "-" + month + "-" + day).toString();
  //   setter && setter(year + "-" + month + "-" + day);
  // };

  // INIT VALUES ///////////////////////////////////////

  /**
   * Convert the value pass to component into the 3 states
   */
  const getInitDate = () => {
    if (value) {
      const initDate = new Date(value);

      setDay(initDate?.getDate()?.toString()?.padStart(2, "0"));
      setMonth((initDate?.getMonth() + 1).toString()?.padStart(2, "0"));
      setYear(initDate?.getFullYear()?.toString());
    }
  };

  /**
   * Get the min and the max Year if min and max are defined
   */
  const getRangeYears = () => {
    if (min) {
      const minDate = new Date(min);
      setMinYear(minDate?.getFullYear()?.toString());
    }
    if (max) {
      const maxDate = new Date(max);
      setMaxYear(maxDate?.getFullYear()?.toString());
    }
  };

  useEffect(() => {
    getInitDate();
    getRangeYears();
  }, [min, max]);

  useEffect(() => {
    handleUpdateFromValue();
    setFirstFocus(false);
  }, [mustUpdate, value]);


  useEffect(() => {
    getInitDate();
    getRangeYears();
  }, [min, max, value]);

  useEffect(() => {
    firstFocus && !isFocus && setter && handleBlurAll(year, month, day);
  }, [isFocus]);

  /// styles

  const styleMiniLabel = { fontSize: "0.7rem", fontWeight: 400, opacity: 0.3 };
  const styleInput = {
    borderRadius: "5px",
    padding: "5px 2px",
    border: "unset",
    background: "none",
    fontWeight: 500,
  };

  return (
    <InputContainer className={classNames("date-input", className)} style={style} id={id}>
      {(legend || legend.length > 0) && <label htmlFor="date-input">{legend}</label>}

      <Grid mediaQuery={mediaQuery} template="1fr auto 1fr auto 2fr" alignItems="center" className="mb-0 mt-0 ml-auto mr-auto mw-200 br-4 p-1 pl-2 pr-2" space={3} style={{ background: disabled ? "var(--color-gray3)" : "var(--color-gray1)" }}>
        {/* DAYS */}
        <div className="column j-center a-center">
          {showLabel && (
            <label htmlFor="day" style={styleMiniLabel}>
              Jour
            </label>
          )}
          <input
            disabled={disabled}
            style={styleInput}
            id="day"
            className="text-center pl-0 pr-0 h-100"
            placeholder="JJ"
            type="text"
            value={day}
            onChange={handleChangeDay}
            onBlur={onBlurDay}
            onFocus={(e) => {
              setIsFocus(true);
              setFirstFocus(true);
              if (e.target.value.length == 2) {
                e.target.select();
              }
            }}
            ref={dayRef}
          />
        </div>
        <span className="m-auto">/</span>
        {/* MONTHS */}
        <div className="column j-center a-center">
          {showLabel && (
            <label htmlFor="month" style={styleMiniLabel}>
              Mois
            </label>
          )}
          <input
            disabled={disabled}
            style={styleInput}
            id="month"
            className="text-center pl-0 pr-0 h-100"
            placeholder="MM"
            type="text"
            value={month}
            onChange={handleChangeMonth}
            ref={monthRef}
            onFocus={(e) => {
              setIsFocus(true);
              setFirstFocus(true);
              if (e.target.value.length == 2) {
                e.target.select();
              }
            }}
            onBlur={onBlurMonth}
          />
        </div>

        <span className="m-auto">/</span>
        {/* YEAR */}
        <div className="column j-center a-center">
          {showLabel && (
            <label htmlFor="year" style={styleMiniLabel}>
              Année
            </label>
          )}
          <input
            disabled={disabled}
            style={styleInput}
            id="year"
            className="text-center pl-0 pr-0 h-100"
            placeholder="AAAA"
            type="text"
            value={year}
            onChange={handleChangeYears}
            ref={yearRef}
            onFocus={(e) => {
              setIsFocus(true);
              setFirstFocus(true);
              if (e.target.value.length == 4) {
                e.target.select();
              }
            }}
            onBlur={onBlurYear}
          />
        </div>
      </Grid>
    </InputContainer>
  );
}
