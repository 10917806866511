////////////////////////////////////////////// STRING ///////////////////////////////////////////////////////////////

export type StringType =
  | "category"
  | "code"
  | "taxationType"
  | "closingMonth"
  | "pesRythm"
  | "phone"
  | "email"
  | "firstName"
  | "lastName"
  | "name"
  | "address"
  | "city"
  | "country"
  | "postalCode"
  | "siret"
  | "siren"
  | "apeCode"
  | "questionTitle"
  | "questionMessage"
  | "answer"
  | "date"
  | "numberOfDay"
  | "title"
  | "message"
  | "description"
  | "password"
  | "type"
  | "journal"
  | "select"
  | "client_id"
  | "client_secret"
  | "custom";

type AssetsType = {
  [key in StringType]: { min: number; max: number; legend: string; };
};

const assets: AssetsType = {
  // company
  category: { min: 2, max: 10, legend: "catégorie" }, // company
  code: { min: 2, max: 10, legend: "code" }, // company
  taxationType: { min: 2, max: 20, legend: "type de taxe" }, // company
  closingMonth: { min: 1, max: 2, legend: "mois de cloture" }, // company
  pesRythm: { min: 2, max: 50, legend: "rythme des points en suspens" }, // company

  phone: { min: 6, max: 15, legend: "téléphone" }, // report, User
  email: { min: 4, max: 255, legend: "email" }, // report, user
  firstName: { min: 2, max: 40, legend: "prénom" }, // report, user
  lastName: { min: 2, max: 40, legend: "nom" }, // report, user
  //
  name: { min: 2, max: 255, legend: "raison sociale" }, // company
  title: { min: 2, max: 255, legend: "titre" },
  address: { min: 1, max: 255, legend: "adresse" },
  postalCode: { min: 1, max: 255, legend: "code postal" },
  city: { min: 1, max: 255, legend: "ville" },
  country: { min: 1, max: 255, legend: "pays" },
  //
  siret: { min: 14, max: 14, legend: "siret" }, // accountingFirm, company
  siren: { min: 9, max: 9, legend: "siren" },
  apeCode: { min: 1, max: 5, legend: "code APE" }, // company
  questionMessage: { min: 6, max: 500, legend: "message" },
  questionTitle: { min: 6, max: 500, legend: "titre" },
  answer: { min: 6, max: 500, legend: "réponse" },
  date: { min: 10, max: 10, legend: "date" },
  numberOfDay: { min: 0, max: 31, legend: "nombre de jour" },
  message: { min: 10, max: 800, legend: "message" },
  description: { min: 0, max: 800, legend: "description" },
  password: { min: 6, max: 50, legend: "mot de passe" }, //User
  type: { min: 0, max: 100, legend: "type d'incident" },
  journal: { min: 0, max: 20, legend: "journal" },
  select: { min: 0, max: 20, legend: "select" },
  client_id: { min: 2, max: 255, legend: "identifiant" },
  client_secret: { min: 2, max: 255, legend: "clé" },

  //
  custom: { min: 0, max: 0, legend: "custom" },
};

type numberToVerify = {
  value: number | string;
  min: number | null;
  max: number | null;
  legend?: string;
};

const verifyLength = ({ value, min, max, legend }: valuesToVerify): string => {
  if (value.length === 0) {
    return `Le champ ${legend} doit être renseigné !`;
  } else if ((min && value.length < min) || (max && value.length > max)) {
    return `Le champ ${legend} doit comporter entre ${min} et ${max} caractères`;
  } else {
    return "";
  }
};

export const verifyEmail = (value: string): string => {
  const validRegex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@([\w-]+\.)+[\w-]{2,8}$/;
  if (value.match(validRegex)) {
    return "";
  } else {
    return "L'adresse mail n'est pas valide";
  }
};

export const verifySiren = (value: string): string => {
  const validRegex = /^[0-9]{9,14}$/;
  if (value.match(validRegex)) {
    return "";
  } else {
    return "Le siret ou siren n'est pas valide";
  }
};

export const verifyPhone = (value: string): string => {
  let tempValue = value.replaceAll("+", "00").replaceAll(".", "").replaceAll(/\s/g, "");
  const validRegex = /^[0-9]{1,}$/;
  if (tempValue.match(validRegex)) {
    return "";
  } else {
    return "Le numéro de téléphone n'est pas valide";
  }
};

const verifyValue = (value: string, legend: string): string => {
  let error = "";
  if (legend === "email") {
    error = verifyEmail(value);
  }
  if (legend === "siret" || legend === "siren") {
    error = verifySiren(value);
  }
  if (legend === "phone") {
    error = verifyPhone(value);
  }

  return error;
};

const verifyNumber = ({ value, min, max, legend }: numberToVerify): string => {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;
  if ((!min || numberValue >= min) && (!max || numberValue <= max)) {
    return "";
  } else {
    return `Le champ ${legend} doit être compris entre ${min} et ${max}`;
  }
};

export const checkString = (value: string | null | undefined, type: StringType | string, min: number | null = null, max: number | null = null): string => {
  const valueTrim = value ? value?.trim() : "";
  let error: string = "";

  if (assets[type as keyof typeof assets] === undefined) {
    return verifyLength({
      value: valueTrim.replace(/\s/g, ""),
      min,
      max,
      legend: type?.toLowerCase(),
    });
  }

  // sinon c'est un champ générique
  error = verifyLength({
    value: valueTrim.replace(/\s/g, ""),
    min: assets[type as keyof typeof assets]?.min,
    max: assets[type as keyof typeof assets]?.max,
    legend: assets[type as keyof typeof assets]?.legend,
  });
  if (error === "") {
    error = verifyValue(valueTrim, type);
  }

  return error;
};

export const checkNumbers = (value: string | number | undefined, type: string): string => {
  if (value !== undefined) {
    if (assets[type as keyof typeof assets] === undefined) {
      return verifyNumber({
        value: value,
        min: assets[type as keyof typeof assets].min,
        max: assets[type as keyof typeof assets].max,
        legend: type?.toLowerCase(),
      });
    }

    // sinon c'est un champ générique
    return verifyNumber({
      value: value,
      min: assets[type as keyof typeof assets].min,
      max: assets[type as keyof typeof assets].max,
      legend: assets[type as keyof typeof assets].legend,
    });
  }
  return "";
};

////////////////////////////////////////////// BOOLEAN///////////////////////////////////////////////////////////////

export type BooleanType = "code" | "cgu" | "toggle" | "secret" | string;

type BooleanAssetsType = { [key in BooleanType]: { truthiness: boolean; legend: string; } };

const booleanAssets: BooleanAssetsType = {
  code: { truthiness: true || false, legend: "code" },
  toggle: { truthiness: true, legend: "toggle" },
  cgu: { truthiness: true, legend: "CGU" },
  secret: { truthiness: true || false, legend: "" },
};

interface booleanToVerify {
  value: boolean;
  truthiness: boolean;
  legend: string;
}

interface valuesToVerify {
  value: string;
  min: number | null;
  max: number | null;
  legend: string;
}

const verifyTruthiness = ({ value, truthiness, legend }: booleanToVerify): string => {
  if (value !== truthiness) {
    return `Le champ ${legend} doit être ${truthiness ? "coché" : "décoché"} !`;
  } else {
    return "";
  }
};

export const checkBoolean = (value: boolean, type: BooleanType): string => {
  if (value !== undefined)
    return verifyTruthiness({
      value: value,
      truthiness: booleanAssets[type].truthiness,
      legend: booleanAssets[type].legend,
    });

  return "";
};
