"use client";
import classNames from "classnames";
import { Dispatch, HTMLAttributes, SetStateAction, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "../../../react-icons/ai";
import { BsXLg } from "../../../react-icons/bs";
import { FaCheckCircle } from "../../../react-icons/fa";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "../../../react-icons/io";
import { Tooltip } from "../../infos";
import { InputContainer } from "../InputContainer";

interface Props extends HTMLAttributes<HTMLInputElement> {
  id?: string;
  className?: string;
  required?: boolean;
  autoComplete?: "off" | "on" | "current-password" | "new-password" | "address-level4";
  registerMode?: boolean;
  secretMode?: boolean;
  direction?: "column" | "row";
  value: string;
  legend?: string;
  setter?: (arg: string) => void;
  placeholder?: string;
  setIsValid?: (arg: boolean) => void;
  setError?: ((arg: string) => {}) | Dispatch<SetStateAction<string>>;
  disabled?: boolean;
};

export function PasswordInput({
  id,
  className,
  value,
  setter,
  autoComplete = "on",
  required = false,
  legend,
  registerMode = false,
  secretMode = false,
  direction = "column",
  placeholder = "Entrez votre mot de passe",
  setIsValid = undefined,
  disabled = false,
  setError,
  ...props
}: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "password";
  const [isSecret, setIsSecret] = useState<boolean>(true);
  const [majuscule, setMajuscule] = useState<boolean>(false);
  const [minuscule, setMinuscule] = useState<boolean>(false);
  const [specialChar, setSpecialChar] = useState<boolean>(false);
  const [passwordLength, setPasswordLength] = useState<boolean>(false);
  const [error, setErrorMessage] = useState<string>("");

  //// REGEX
  const charact = `;_!"#$&'()*+,.\-`;

  const noOtherCharacRegex = new RegExp(`^[A-Za-z0-9${charact}]*$`, "gm");
  const majRegex = new RegExp("[A-Z]");
  const minRegex = new RegExp("[a-z]");
  const specRegex = new RegExp(`[${charact}]`);

  const handleChange = (value: string) => {
    let tmp_data = JSON.parse(JSON.stringify(value));
    tmp_data = value.trim();

    let tempPassword = value.trim();

    if (registerMode) {
      let errorComputed = "";

      // CHECKS
      tempPassword.length >= 8 ? setPasswordLength(true) : setPasswordLength(false);
      setMajuscule(majRegex.test(tempPassword));
      setMinuscule(minRegex.test(tempPassword));
      setSpecialChar(specRegex.test(tempPassword));

      /// CHECK ERROR UNDER INPUT

      if (tempPassword.length >= 8 && majRegex.test(tempPassword) && minRegex.test(tempPassword) && specRegex.test(tempPassword)) {
        errorComputed = "";
      } else {
        errorComputed = "Le mot de passe n'est pas correct.";
      }

      if (tempPassword.match(noOtherCharacRegex) == null) {
        errorComputed = "Le mot de passe contient un caractère incorrect.";
      }

      setErrorMessage(errorComputed);
      setError && setError(errorComputed);
      setIsValid && setIsValid(errorComputed?.length > 0 ? true : false);
    }
    setter && setter(tmp_data);
  };

  return (
    <InputContainer showError={!registerMode && !!error && error?.length > 0} errorMessage={error} className={classNames(direction, className)}>
      <label hidden={!legend} htmlFor={NAMESPACE}>
        {legend} {required && "*"}
      </label>

      <div className="password-container">
        <div className="password-valid">
          <input
            {...props}
            disabled={disabled || !setter}
            type={(isSecret || secretMode) ? "password" : "text"}
            id={NAMESPACE}
            value={value as string}
            onChange={(e: any) => !disabled && handleChange(e.target.value)}
            required={required}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />

          {!secretMode
            && <>{isSecret ? (
              <AiFillEye onClick={() => setIsSecret(false)} className="eye clickable" size={25} />
            ) : (
              <AiFillEyeInvisible onClick={() => setIsSecret(true)} className="eye clickable" size={25} />
            )}          </>}

          {registerMode && typeof value === "string" && value?.length !== 0 && (
            <div className="confirmation">
              <Tooltip legend={error || ""}>
                {error ? <BsXLg className="unvalid" size={20} /> : <FaCheckCircle color="var(--color-success)" size={20} className="validation" />}
              </Tooltip>
            </div>
          )}
        </div>
        {registerMode && (
          <div className="password-info">
            <ul>
              {[minuscule, majuscule, specialChar, passwordLength].map((verif, index) => (
                <li key={`verif_${index}`}>
                  {verif ? <IoMdCheckmarkCircle className="valid" size={20} /> : <IoMdCloseCircle className="unvalid" size={20} />}
                  {index === 0 && "Minuscule"}
                  {index === 1 && "Majuscule"}
                  {index === 2 && `Caractères spéciaux: ${charact?.split("").join(" ")}`}
                  {index === 3 && "Au moins 8 caractères"}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </InputContainer>
  );
}
