'use client';
import { HTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CriteriaType, SearchInput } from '../searchInput/SearchInput';
import { InputContainer } from '../InputContainer';
import { QuestionCategoryType } from '../../../../utilities/redux/types';
import { IconButton } from '../../buttons';
import { Grid } from '../../../components/blocs';
import { changeColorOpacity } from '../../../../utilities/tools/color';
import { CategoryIcon } from '../../../components/icons';
import { CategoryBadge } from '../../../components/badges';
import { SuggestionQuestionCategoryType } from '../searchInput/parts/SearchSuggestions';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  legend?: string;
  value?: QuestionCategoryType;
  setter?: (arg: QuestionCategoryType) => void;
  direction?: "column" | "row";

  // Search
  handleSearch: (search: string, signal: AbortSignal, criteria?: CriteriaType, ...any) => Promise<SuggestionQuestionCategoryType[]> | SuggestionQuestionCategoryType[] | void;
  isLoading: boolean;

  // ERROR
  showError?: boolean;
  setError?: (error: string) => void;
}

export function CategoryInput({
  className,
  handleSearch,
  isLoading,
  id,
  required = false,
  disabled = false,
  placeholder,
  direction = "column",
  legend,
  value = null,
  setter,
  setError,
  showError = false,
  ...props }: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "textInput";
  const [errorMessage, setErrorMessage] = useState("");

  // Check if value is correct
  function error(targetValue: string) {
    if (required && !targetValue) {
      const errorComputed = "Vous devez choisir un code APE";

      setError && setError(errorComputed);

      return errorComputed;
    }
    return "";
  }

  const handleChange = (e) => {
    setErrorMessage(error(e?.code));
    setter(e);
  };

  useEffect(() => {
    setErrorMessage(error(value?.code));
  }, [value]);

  return (
    <InputContainer {...props} className={classNames("category-input", className)} errorMessage={errorMessage} showError={showError} direction={direction}>
      {legend && <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend} {required && "*"}
      </label>}

      {disabled
        ? (value?.title
          ? <CategoryBadge category={value} />
          : <div className='m-0 tile gray-light row a-center'>
            Aucun catégorie renseignée
          </div>)
        : (value?.title
          ? <Grid
            template="auto 1fr auto" alignItems="center" mediaQuery={0}
            style={{
              backgroundColor: value.color && changeColorOpacity(value.color, 0.2),

              borderColor: value.color && changeColorOpacity(value.color, 0.4),
              color: value?.color
            }}
            className={"badge empty medium fit-content"}
          >
            <CategoryIcon
              iconName={value.icon}
              // size={iconSize}
              color={value?.color}
            />
            {value.title}

            <IconButton className='ml-auto' size={18} form='circle' icon='close' cb={() => setter(null)} type='empty' />
          </Grid>
          : <SearchInput
            entity='category'
            placeholder={placeholder}
            aria-labelledby={`${NAMESPACE}_label`}
            id={NAMESPACE}
            isLoading={isLoading}
            handleSearch={handleSearch}
            setSuggestionSelected={handleChange}
          />)}

      { }
    </InputContainer>
  );
}