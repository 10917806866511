"use client";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { FaCheckCircle } from "../../../react-icons/fa";
import { InputContainer } from "../InputContainer";
import { StringType, checkString } from "../_validator";

interface Props extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  legend?: string;
  value?: string;
  setter?: (arg: string) => void;
  height?: number;
  minHeight?: number;

  // ERROR
  setError?: (error: string) => void;
  type?: StringType | { min: number; max: number; };
  showError?: boolean;
  showValidated?: boolean;
  direction?: "row" | "column";
}

export const TextArea = ({
  id,
  required = false,
  disabled = false,
  placeholder,
  legend,
  value = "",
  setter,
  autoFocus = false,
  setError,
  showError = false,
  showValidated = false,
  type = { min: 0, max: 255 },
  height = 1,
  minHeight,
  direction = "column",
  onBlur,
  ...props
}: Props) => {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "textArea";
  const [errorMessage, setErrorMessage] = useState("");
  const textAreaRef: any = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 2 + "px";
  };

  // Check if value is correct
  function error(targetValue: string) {
    if (!!targetValue || required) {
      let errorComputed = typeof type === "string"
        ? checkString(targetValue, type)
        : checkString(targetValue, legend ?? id ?? "custom", type.min, type.max);
      setError && setError(errorComputed);

      return errorComputed;
    }
    return "";
  }

  const handleChange = (e) => {
    setErrorMessage(error(e.target.value));

    setter && setter(e.target.value);
  };

  const handleBlur = (e) => {
    setErrorMessage(error(e.target.value));
    onBlur && onBlur(e);
  };

  function onFocus(e: any) {
    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    props.onFocus && props.onFocus(e);
  }

  useEffect(() => {
    setErrorMessage(error(value));
  }, [value]);

  useEffect(() => {
    resizeTextArea();
  }, [textAreaRef?.current?.value]);

  return (
    <InputContainer errorMessage={errorMessage} showError={showError} direction={direction} {...props}>
      <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend ?? "text"} {required && "*"}
      </label>

      <div className="textarea-input">
        <textarea
          style={{ minHeight: minHeight != null ? minHeight + "px" : "unset" }}
          aria-labelledby={`${NAMESPACE}_label`}
          id={NAMESPACE}
          autoFocus={autoFocus}
          ref={textAreaRef}
          value={value}
          data-type={type}
          placeholder={placeholder}
          required={required}
          disabled={!setter || disabled}
          onFocus={onFocus}
          rows={height}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
        />

        {showValidated && errorMessage?.length === 0 && <FaCheckCircle color="var(--color-success)" size={20} className="validation" />}
      </div>
    </InputContainer>
  );
};
