import { Tooltip } from "../../../components/infos";

type ElementType = {
  legend: string;
  color: string;
};

type Props = {
  elements?: ElementType[];
  size?: number;
  cb: (arg: string) => void;
};

const ColorPresetPicker = ({
  elements = [
    { color: "hsl(0, 40%, 49%)", legend: "Brun" },
    { color: "hsl(334, 100%, 55%)", legend: "Rose" },
    { color: "hsl(275, 65%, 33%)", legend: "Violet" },
    { color: "hsl(180, 48%, 41%)", legend: "Vert canard" },
    { color: "hsl(241, 48.30%, 41.00%)", legend: "Bleu" },
    { color: "hsl(154, 48%, 50%)", legend: "Olive" },
  ],
  cb,
  size = 30,
}: Props) => {
  return (
    <div className="row a-center wrap mt-1 mb-1" style={{ gap: "5px" }}>
      {elements?.map((elem, key) => {
        return (
          <Tooltip legend={elem.legend} key={key}>
            <div
              className="br-10 clickable"
              style={{ width: size + "px", height: size + "px", backgroundColor: elem.color, border: "2px solid var(--color-gray2)" }}
              onClick={() => cb(elem?.color)}
            ></div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ColorPresetPicker;
