"use client";
import classNames from "classnames";
import React, { HTMLAttributes, forwardRef, type JSX } from "react";

export interface OptionPropsType extends HTMLAttributes<HTMLLIElement> {
  namespace?: string;
  value: string;
  legend: string;
  selectedValue?: string;
}

export const OptionInput = forwardRef(function OptionInput(
  { namespace, value, legend, selectedValue = "", ...props }: OptionPropsType,
  ref: any
): JSX.Element {
  return (
    <li
      tabIndex={0}
      ref={ref}
      id={`${namespace}_element_${value}`}
      role="option"
      aria-selected={value === selectedValue ?? false}
      {...props}
      className={classNames(props?.className, "option")}
      value={value}
    >
      {legend}
    </li>
  );
});
