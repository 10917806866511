"use client";
import classNames from "classnames";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { BsCheck } from "../../../react-icons/bs";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { InputContainer } from "../InputContainer";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  value: boolean;
  setter?: (checked: boolean) => void;
  id?: string;
  size?: number;
  required?: boolean;
  disabled?: boolean;
  direction?: "column" | "row" | "row-reverse";
  legend?: string;
  setError?: (error: string) => void;
  showError?: boolean;
  checkedColor?: CssVariablesType;
  uncheckedColor?: CssVariablesType;
}

/**
 * Checkbox component with custom styling, error handling, and accessibility.
 */
export const CheckBoxInput = ({
  id,
  legend,
  value,
  setter,
  size = 22,
  required = false,
  disabled = false,
  direction = "row",
  setError,
  showError = false,
  checkedColor = "main",
  uncheckedColor = "gray1",
  ...props
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const NAMESPACE = id ?? legend?.replace(/\s+/g, "_") ?? "checkbox";

  useEffect(() => {
    const error = value || !required ? "" : "La case doit être cochée";
    setError && setError(error);
    setErrorMessage(error);
  }, [value, required, setError]);

  const handleToggle = () => {
    if (!disabled && setter) {
      setter(!value);
    }
  };

  return (
    <InputContainer {...props} showError={showError} errorMessage={errorMessage} className={classNames("checkbox gap-1", props.className)} direction={direction}>
      {legend ? (
        <label id={`${NAMESPACE}_label`} htmlFor={NAMESPACE} className="clickable m-0" onClick={handleToggle} style={{ userSelect: "none" }}>
          {legend} {required && "*"}
        </label>
      ) : <></>}

      <div
        id={`${NAMESPACE}_checkbox`}
        tabIndex={0}
        role="checkbox"
        aria-checked={value}
        aria-label={value ? "Décocher" : "Cocher"}
        className={classNames("checkbox-input clickable", { disabled })}
        onClick={handleToggle}
        style={{
          width: size,
          height: size,
          backgroundColor: `var(--color-${value ? checkedColor : uncheckedColor})`,
        }}
      >
        {value && <BsCheck color="var(--color-gray0)" fill="var(--color-gray0)" size={size - 10} />}
      </div>

      <input
        ref={inputRef}
        type="checkbox"
        id={NAMESPACE}
        disabled={disabled || !setter}
        checked={value}
        onChange={() => setter && setter(!value)}
        hidden
        aria-hidden
        aria-labelledby={`${NAMESPACE}_label`}
        required={required}
      />
    </InputContainer>
  );
};
