'use client';
import type { JSX } from "react";

type Props = {
  children: JSX.Element | JSX.Element[];
  legend: string;
};

export function Step({ children }: Props) {

  return (
    <div>
      {children}
    </div>
  );
}