"use client";

import classNames from "classnames";
import { useEffect, useRef, useState, type JSX } from "react";
import { IconButton } from "../../buttons";
import { InputContainer } from "../InputContainer";

type Props = {
  id?: string;
  placeholder?: string;
  value?: string;
  legend?: string;
  direction?: "column" | "row";
  className?: string;
  addToast?: (arg: { type: string; legend: string; }) => void;
  badge?: JSX.Element | null;
  fontSize?: React.CSSProperties["fontSize"];
};

export function CopyTextInput({ id, legend, placeholder, value, direction = "column", className = "", addToast, badge = null, fontSize }: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "CopyInput";
  const textAreaRef: any = useRef(null);
  const [result, setResult] = useState<null | { state: "success"; } | { state: "error"; message: string; }>(null);

  const copy = async (e) => {
    e.stopPropagation();
    const text = value;

    try {
      await navigator.clipboard.writeText(text);
      setResult({ state: "success" });
      addToast && addToast({ legend: "Copié dans le presse-papier !", type: "good" });
    } catch (e) {
      setResult({ state: "error", message: e.message });
      addToast && addToast({ legend: `Erreur: ${e.message}`, type: "fail" });
      throw e;
    } finally {
      setTimeout(() => {
        setResult(null);
      }, 2000);
    }
  };

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight - 0 + "px";
  };

  useEffect(() => {
    resizeTextArea();
  }, [textAreaRef?.current?.value]);

  return (
    <InputContainer className={classNames(className)} direction={direction}>
      <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend ?? "copy"}
        {badge}
      </label>

      <div className="copy-text-input" onClick={(e: any) => copy(e)}>
        <textarea rows={1} ref={textAreaRef} readOnly id={NAMESPACE} value={value as string} placeholder={placeholder} autoComplete="one-time-code" style={{ fontSize }} />

        <IconButton tooltipPosition="left" tooltip="Copier" icon="copyText" type="blue-light" form="circle" cb={copy} className="m-0" size={15} />

        {result !== null && !addToast && (
          <div className="info">
            {result?.state === "success" && "Copié dans le presse-papier !"}
            {result?.state === "error" && `Erreur: ${result.message}`}
          </div>
        )}
      </div>
    </InputContainer>
  );
}
