import classNames from "classnames";
import { HTMLAttributes, Ref } from "react";
import { AiOutlineSearch } from "../../../../react-icons/ai";
import { CircleLoader } from "../../../loaders";

interface Props extends HTMLAttributes<HTMLInputElement> {
  value: string;
  legend?: string;
  placeholder?: string;
  isLoading?: boolean;
  setter: (arg: string) => void;
  size?: "small" | undefined;
  inputRef?: Ref<HTMLInputElement>;
}

export function SearchInputText({ value, setter, isLoading = false, legend, placeholder = "Entrez votre recherche", inputRef, size, ...props }: Props) {
  return (
    <div {...props} className={classNames(props.className, "search-input", size)}>
      {legend && legend?.length > 0 && (
        <label htmlFor="search" className="">
          {legend}
        </label>
      )}
      <div className="input">
        <AiOutlineSearch size={20} />

        <input
          autoComplete="off"
          ref={inputRef}
          id="search"
          type="text"
          onChange={(e: any) => {
            setter(e.target.value);
          }}
          placeholder={placeholder}
          value={value}
        />

        {isLoading == true && <CircleLoader width={"28"} strokeWidth={12} />}
      </div>
    </div>
  );
}
