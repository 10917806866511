import React from "react";

export type ToogleItemType = {
  legend: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  array: ToogleItemType[];
  disabledItems?: boolean[];
  selectedItems: boolean[];
  setSelectedItems: (newArr: boolean[]) => void;
  direction?: "row" | "column";
};

export function ToggleArrayInput({
  array,
  selectedItems,
  setSelectedItems,
  direction = "row",
  disabledItems,
}: Props) {
  const handleChange = (index: number) => {
    let temp = JSON.parse(JSON.stringify(selectedItems));

    temp[index] = !temp[index];
    setSelectedItems(temp);
  };

  return (
    <div className="column">
      {array.map((item, key) => (
        <div
          className={
            disabledItems?.[key] === false
              ? "toggle-item"
              : "toggle-item disabled"
          }
          style={{
            flexDirection: direction,
            width: "100%",
            justifyContent: "space-between",
          }}
          key={key}
        >
          {item.legend.length > 0 && (
            <label htmlFor={item.legend}>{item.legend}</label>
          )}
          <label className="switch">
            <input
              type="checkbox"
              id={item.legend}
              checked={selectedItems[key]}
              onChange={
                disabledItems && disabledItems[key] === false
                  ? () => handleChange(key)
                  : () => { }
              }
            />
            <span className="slider" />
          </label>
        </div>
      ))}
    </div>
  );
}
