import { HTMLAttributes, useEffect, useState } from "react";
import { SearchCriteria } from "./parts/SearchCriteria";

import classNames from "classnames";
import { IoCloseCircleOutline } from "../../../react-icons/io5";
import { SearchInputText } from "./parts/SearchInputText";
import { SearchSuggestions, SuggestionType } from "./parts/SearchSuggestions";

export type EntityType = "user" | "company" | "accountingFirm" | "question" | "category" | "questionCategory" | "fecImport" | "questionExchange" | "account" | "apeCode";

export type CriteriaType = {
  legend: string;
  value: string;
};

interface Props extends HTMLAttributes<HTMLInputElement> {
  initialSearch?: string;
  id?: string;
  legend?: string;
  placeholder?: string;
  entity?: EntityType;
  setSuggestionSelected?: (arg: any) => void;
  isLoading?: boolean;
  handleSearch: (search: string, signal: AbortSignal, criteria?: CriteriaType,) => Promise<SuggestionType[]> | SuggestionType[] | void;
  cb?: (search: string) => void;
  criterias?: CriteriaType[];
  className?: string;
  size?: "small";
}

export function SearchInput({
  initialSearch = "",
  id,
  legend,
  handleSearch,
  cb,
  criterias = [],
  placeholder = "Entrez votre recherche",
  setSuggestionSelected,
  entity,
  className = "",
  size,
  ...props
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(props?.isLoading ?? false);
  const [search, setSearch] = useState<string>(initialSearch);
  const [currentCriteria, setCurrentCriteria] = useState<any>(criterias[0]);
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const [suggestionsIsOpen, setSuggestionsIsOpen] = useState<boolean>(false);

  async function handleSearchAndFetch(search: string, signal: AbortSignal, criteria?: CriteriaType) {
    const fetchResult = await handleSearch(search, signal, criteria);

    if (search && fetchResult) {
      setSuggestionsIsOpen(true);
      setSuggestions(fetchResult);
    } else setSuggestionsIsOpen(false);
    setIsLoading(false);
  }

  useEffect(() => {
    const abortController = new AbortController();
    const getData = setTimeout(() => {
      setIsLoading(true);
      handleSearchAndFetch(search, abortController.signal, currentCriteria);
    }, 1500);

    return () => {
      clearTimeout(getData);
      abortController.abort();
    };
  }, [search, currentCriteria, id]);

  useEffect(() => {
    criterias && !currentCriteria && setCurrentCriteria(criterias?.[0]);
  }, [criterias]);

  return (
    <div className={classNames("search-container", className)} id={id && id} style={{ gridTemplateColumns: criterias && criterias?.length > 0 ? "2fr 1fr" : "1fr" }}>
      <div className="column mt-0" style={{ position: "relative" }}>
        <SearchInputText
          {...props}
          isLoading={props?.isLoading ?? isLoading}
          legend={legend || ""}
          placeholder={placeholder}
          value={search}
          setter={(e) => {
            setSearch(e);
            cb && cb(e);
          }}
          size={size}
        />

        {!isLoading && search?.length > 0 && (
          <IoCloseCircleOutline onClick={() => setSearch("")} className="clickable" size={30} color="var(--color-gray12)" style={{ position: "absolute", bottom: "10px", right: "10px" }} />
        )}

        {search && entity && suggestions && setSuggestionSelected && suggestionsIsOpen == true && (
          <SearchSuggestions search={search} suggestions={suggestions} entity={entity} setSuggestionSelected={setSuggestionSelected} setSuggestionsIsOpen={setSuggestionsIsOpen} />
        )}
      </div>

      {criterias && criterias.length > 0 && currentCriteria && <SearchCriteria criterias={criterias} selectedCriteria={currentCriteria} setSelectedCriteria={setCurrentCriteria} />}
    </div>
  );
}
