export function generateYearCalendar(year = 2025) {
  const months = [];

  // Tableau des jours par mois pour une année non bissextile
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Vérifie si l'année est bissextile
  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  // Si l'année est bissextile, février a 29 jours
  if (isLeapYear) {
    daysInMonth[1] = 29;
  }

  // Génère un tableau pour chaque mois
  for (let i = 0; i < 12; i++) {
    const days = Array.from({ length: daysInMonth[i] }, (_, day) => day + 1);
    months.push(days);
  }

  return months;
}
