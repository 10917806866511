import React from "react";
import { SwiperComponent } from "..";
import { SwiperSlide } from "swiper/react";
import { WaveLoader } from "../../../components/loaders";

type Props = {
  months: string[];
  monthSelected: string;

  handleChangeSlide?: (month: string) => void;
  onClick?: (month: string) => void;
};

export function MonthSwiper({ months, handleChangeSlide, monthSelected, onClick }: Props) {
  return (
    <div className="tile" id="month-swiper">
      {Array?.isArray(months) && months?.length > 0
        && <SwiperComponent
          slidesPerView={"auto"}
          navigation={true}
          className="pl-2 pr-2"
          initialSlide={months?.findIndex((item) => item === monthSelected)}
          onSlideChange={(i) => handleChangeSlide && handleChangeSlide(months[i])}
        >
          {months?.map((month, key) => {
            return (
              <SwiperSlide key={key} onClick={() => onClick && onClick(month)}>
                {({ isActive }) => (
                  <div className={isActive ? "month selected" : "month"}>
                    {new Date(month).toLocaleDateString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </div>
                )}
              </SwiperSlide>
            );
          })}

        </SwiperComponent>}

      {(!Array?.isArray(months) || months?.length === 0) && <WaveLoader width="70px" />}
    </div>
  );
}
