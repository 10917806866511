import { useEffect, useState } from "react";
import { DateInput2 } from "../../inputs";

type Props = {
  startDate: Date;
  endDate: Date;
  setStartDate: (arg: Date) => void;
  setEndDate: (arg: Date) => void;
};

const CalendarWrapperHeader = ({ startDate, endDate, setStartDate, setEndDate }: Props) => {
  const [mustUpdate, setMustUpdate] = useState(0);
  useEffect(() => {
    setMustUpdate(Math.random());
  }, [startDate, endDate]);

  return (
    <div className="pt-1 pb-2 row a-center j-center">
      <p>Du</p>
      <DateInput2
        mustUpdate={mustUpdate}
        value={startDate && startDate?.getFullYear() + "-" + startDate?.getMonth() + 1 + "-" + startDate?.getDate()}
        setter={(e) => setStartDate(new Date(e))}
      />
      <p>au</p>

      <DateInput2 mustUpdate={mustUpdate} value={endDate && endDate?.getFullYear() + "-" + endDate?.getMonth() + 1 + "-" + endDate?.getDate()} setter={(e) => setEndDate(new Date(e))} />
    </div>
  );
};

export default CalendarWrapperHeader;
