"use client";
import classNames from "classnames";
import { HTMLAttributes, useEffect, useState } from "react";
import { checkString } from "../_validator";
import { InputContainer } from "../InputContainer";
import { OptionPropsType } from "./OptionInput";

interface Props extends HTMLAttributes<HTMLDivElement> {
  value?: string;
  setter?: (arg: string) => void;
  id?: string;
  legend?: string;
  required?: boolean;
  direction?: "column" | "row";
  placeholder?: string;
  size?: "small" | "medium" | "large";
  disabled?: boolean;

  // ERROR
  setError?: (error: string) => void;
  showError?: boolean;
}

export function SelectInput({ id, placeholder, required = false, value, legend, setter, children, direction = "column", size = "medium", setError, showError, disabled, ...props }: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "select";

  const [errorMessage, setErrorMessage] = useState("");
  const childrenArray = (Array.isArray(children) ? children.filter((i) => i) : [children]).flat();

  function computeError(value?: string) {
    if (required == true && (value?.length === 0 || value === null || value === undefined)) {
      const error = checkString(value, legend ?? id ?? "custom");
      setErrorMessage(error);
      setError && setError(error);
    } else {
      setErrorMessage("");
      setError && setError("");
    }
  }

  const handleChange = (value: string) => {
    setter && setter(value);
    computeError(value);
  };

  useEffect(() => {
    computeError(value);
  }, []);

  return (
    <InputContainer {...props} className={classNames("input-container", props.className, size)} direction={direction} errorMessage={errorMessage} showError={showError}>
      <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend ?? "select"} {required && "*"}
      </label>

      <select
        disabled={!setter || disabled}
        required={required}
        // defaultValue={value}
        className={classNames(size, (!setter || disabled) ? "disabled" : "clickable")}
        id={NAMESPACE}
        value={value}
        onChange={(e: any) => handleChange(e.target.value)}
      >
        <option key={`selectOption`} value={null}>
          -- {placeholder || `Sélectionnez ${legend ? legend.toLowerCase() : "une option"}`} --
        </option>
        {childrenArray
          ?.filter((i) => i)
          .map((option) => {
            const props: OptionPropsType = option?.props;

            return (
              <option key={`selectOption_${props?.value}_${props?.legend.replaceAll(/\s/g, "")}`} value={props?.value}>
                {props?.legend}
              </option>
            );
          })}
      </select>
    </InputContainer>
  );
}
