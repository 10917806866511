import classNames from "classnames";
import { useEffect, useState } from "react";
import { diffDaysFromDates } from "../../../../utilities/tools/date";
import { Grid } from "../../../components/blocs";
import { generateYearCalendar } from "./_utils";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (arg: Date) => void;
  setEndDate: (arg: Date) => void;
};

const CalendarInput = ({ startDate, endDate, setStartDate, setEndDate }: Props) => {
  const [currentYear, setCurrentyear] = useState(2025);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [dates, setDates] = useState([]);
  const [decalage, setDecalage] = useState(0);

  const initDates = () => {
    let joursDeLanne = generateYearCalendar(2025);
    console.log(joursDeLanne);
    setDates(joursDeLanne);
    setStartDate(new Date());
    setEndDate(new Date());
    calculDecalage(joursDeLanne[currentMonth]);
  };

  const calculDecalage = (joursDuMois: number[]) => {
    console.log("init decallage");

    console.log(new Date(currentYear + "-" + currentMonth + joursDuMois[currentMonth]));
    let firstDayOfMonth = new Date(currentYear + "-" + currentMonth + joursDuMois[currentMonth]);
    let countDecalage = firstDayOfMonth?.getDay() - 1;
    console.log(countDecalage);
    setDecalage(countDecalage);

    /// Ajout des jours du mois précédent
    for (let deb = 0; deb < countDecalage; deb++) {
      joursDuMois.unshift(0);
    }
    // Ajout des jours du mois suivant
    while (joursDuMois.length < 35) {
      joursDuMois.push(0);
    }

    let tempDates = dates;
    tempDates[currentMonth] = joursDuMois;

    setDates(tempDates);
  };

  const handleSelectDates = (item) => {
    // setStartDate(new Date(currentYear, currentMonth, item));

    if (startDate == null && endDate == null) {
      console.log("pas de dates");
      setStartDate(new Date(currentYear, currentMonth, item));
      return;
    }

    if (startDate != null && endDate == null) {
      console.log("date start définie");
      let tempEndDate = new Date(currentYear, currentMonth, item);

      if (tempEndDate > startDate) {
        setEndDate(tempEndDate);
      } else {
        setStartDate(tempEndDate);
      }
      return;
    }

    if (startDate !== null && endDate !== null) {
      console.log("les deux dates sont définies");
      setStartDate(new Date(currentYear, currentMonth, item));
      setEndDate(null);
      return;
    }
  };

  useEffect(() => {
    initDates();
  }, []);

  useEffect(() => {
    if (startDate) {
      console.log("start day date");
      console.log(startDate?.getDate());
    }
  }, [startDate]);

  function isSameDate(date1, date2) {
    if (date1 && date2) {
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }
  }

  return (
    <div className="column w-100 calendarInput">
      <button onClick={() => initDates()}>init</button>
      {/* <CalendarMonthChoice /> */}
      <Grid template="repeat(7, 1fr)" mediaQuery={0} space={5}>
        <div className="header">Lun</div>
        <div className="header">Mar</div>
        <div className="header">Mer</div>
        <div className="header">Jeu</div>
        <div className="header">Ven</div>
        <div className="header">Sam</div>
        <div className="header">Dim</div>

        {dates?.[0]?.map((item, key) => {
          let maDate = new Date(currentYear, currentMonth, item);
          // maDate.setHours(1);
          // if (item == 15 && endDate && maDate) {
          //   console.log(maDate, diffDaysFromDates(endDate, maDate));
          // }

          return (
            <div
              className={classNames("body", {
                selected: isSameDate(maDate, startDate) || isSameDate(maDate, endDate),
                interstice: endDate != null && startDate != null && diffDaysFromDates(endDate, maDate) < 0 && diffDaysFromDates(maDate, startDate) < 0,
              })}
              key={key}
              onClick={() => handleSelectDates(item)}
            >
              {item}
            </div>
          );
        })}
      </Grid>
    </div>
  );
};

export default CalendarInput;
