import { useEffect, useState } from "react";
import { HslaColor, HslaColorPicker } from "react-colorful";
import { hslStringToHslObject } from "../../../../utilities/tools/color";

type Props = {
  color: string;
  setColor: (arg: string) => void;
};

const ColorPickerInput = ({ color = "hsl(0, 0%, 0%)", setColor }: Props) => {
  const [colorObject, setColorObject] = useState(null);

  const hslToColor = (hsl: HslaColor) => {
    return `hsl(${hsl?.h}, ${hsl?.s}%, ${hsl?.l}%)`;
  };

  useEffect(() => {
    // console.log("colorObject", colorObject);
    // console.log("color", color);

    if (colorObject == null && color) {
      setColorObject(hslStringToHslObject(color));
    }
  }, [color, colorObject]);

  // useEffect(() => {
  //   setColor(`hsl(${319}, ${0}%, ${0}%)`);
  // }, [color]);

  return (
    <HslaColorPicker
      color={colorObject ?? { h: 0, s: 0, l: 0, a: 1 }}
      onChange={(e) => {
        setColorObject(e);
        setColor && setColor(hslToColor(colorObject));
      }}
    />
  );
};

export default ColorPickerInput;
