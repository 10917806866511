import { SwiperSlide } from "swiper/react";
import { SwiperComponent } from "../../swiper";

type Props = {
  currentMonth: number;
  setCurrentMonth: (arg: number) => void;
};

const CalendarMonthChoice = ({ currentMonth, setCurrentMonth }: Props) => {
  const MONTHS = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

  return (
    <div className="row mw-600 monthSwiper">
      <SwiperComponent slidesPerView={1.1} spaceBetween={10} initialSlide={currentMonth} onSlideChange={setCurrentMonth}>
        {MONTHS?.map((month, key) => {
          return (
            <SwiperSlide key={key} className="monthSlide">
              {month}
            </SwiperSlide>
          );
        })}
      </SwiperComponent>
    </div>
  );
};

export default CalendarMonthChoice;
