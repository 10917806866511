"use client";
import classNames from "classnames";
import { HTMLAttributes, HTMLInputAutoCompleteAttribute, useEffect, useState } from "react";
import { FaCheckCircle } from "../../../react-icons/fa";
import { InputContainer } from "../InputContainer";
import { StringType, checkString } from "../_validator";

interface Props extends HTMLAttributes<HTMLInputElement> {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  legend?: string;
  value?: string;
  setter?: (arg: string) => void;
  direction?: "column" | "row";

  // ERROR
  setError?: (error: string) => void;
  type?: StringType | { min: number; max: number; };
  autoComplete?: HTMLInputAutoCompleteAttribute;
  showError?: boolean;
  showValidated?: boolean;
}

/**
 * @param [id=legend] - allow click on label
 * @param [required=false]
 * @param [disabled=false]
 * @param legend label over the input
 * @param value value of the input
 * @param setter set value of the input
 * @param setError retrieve error of the input
 * @param showError show error over the iput
 * @param showValidated show check in the iput
 * @param [type={ min: 0, max: 0 }] - some type are auto (see StringType), if you want custom set min and max
 */
export function TextInput({
  id,
  required = false,
  disabled = false,
  placeholder,
  direction = "column",
  legend,
  value = "",
  setter,
  setError,
  autoComplete = "on",
  showError = false,
  showValidated = false,
  type = { min: 0, max: 255 },
  onBlur,
  ...props
}: Props) {
  const NAMESPACE = id ?? legend?.replaceAll(" ", "_") ?? "textInput";
  const [errorMessage, setErrorMessage] = useState("");

  // Check if value is correct
  function error(targetValue: string) {
    if (!!targetValue || required) {
      let errorComputed = typeof type === "string" ? checkString(targetValue, type) : checkString(targetValue, legend ?? id ?? "custom", type.min, type.max);
      setError && setError(errorComputed);

      return errorComputed;
    }
    return "";
  }

  const handleChange = (e: any) => {
    setErrorMessage(error(e.target.value));

    setter && setter(e.target.value);
  };

  const handleBlur = (e: any) => {
    onBlur && onBlur(e);
    setErrorMessage(error(e.target.value));
  };

  useEffect(() => {
    setErrorMessage(error(value));
  }, [value]);

  useEffect(() => {
    setErrorMessage(error(value));
  }, []);

  return (
    <InputContainer {...props} className={classNames("text-input", props.className)} errorMessage={errorMessage} showError={showError} direction={direction}>
      <label hidden={!legend} id={`${NAMESPACE}_label`} htmlFor={NAMESPACE}>
        {legend ?? "text"} {required && "*"}
      </label>

      <input
        aria-labelledby={`${NAMESPACE}_label`}
        id={NAMESPACE}
        data-errormessage={errorMessage}
        data-type={type}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={!setter || disabled}
        onBlur={(e) => {
          handleBlur(e);
        }}
        onChange={(e) => {
          handleChange(e);
        }}
        autoComplete={autoComplete}
      />
      {(showValidated && errorMessage?.length === 0)
        ? <FaCheckCircle color="var(--color-success)" size={20} className="validation" />
        : <></>}
    </InputContainer>
  );
}
