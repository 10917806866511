import { OptionInput, SelectInput } from "../..";
import { CriteriaType } from "../SearchInput";

type Props = {
  selectedCriteria: CriteriaType;
  setSelectedCriteria: (arg: CriteriaType) => void;
  criterias: CriteriaType[];
};

export function SearchCriteria({
  criterias,
  setSelectedCriteria,
  selectedCriteria,
}: Props) {
  return (
    <>
      <SelectInput
        className="clickable"
        id="criteria"
        value={selectedCriteria.value}
        setter={(data) => {
          setSelectedCriteria(
            criterias.find(
              (criteria: CriteriaType) => criteria?.value === data
            ) || criterias[0]
          );
        }}
      >

        {criterias?.map(item =>
          <OptionInput
            key={`option_${item?.value}`}
            value={item?.value as string}
            legend={item?.legend}
          />
        )}
      </SelectInput>
    </>
  );
}
