import { useEffect, useState } from "react";
import { Grid } from "../../../components/blocs";
import { BtnContainer, Button } from "../../../components/buttons";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
};

const CalendarWrapperFooter = ({ startDate, endDate }: Props) => {
  const [countDays, setCountDays] = useState(null);

  function datesDiff(a, b) {
    a = a.getTime();
    b = (b || new Date()).getTime();
    var c = a > b ? a : b,
      d = a > b ? b : a;
    return Math.abs(Math.ceil((c - d) / 86400000));
  }

  useEffect(() => {
    if (startDate && endDate) {
      setCountDays(datesDiff(endDate, startDate) + 1);
    }
  }, [startDate, endDate]);

  return (
    <Grid template="1fr auto" alignItems="center" mediaQuery={0} className="calendarWrapperFooter w-100 mt-2 mb-0" style={{ width: "600px" }}>
      <p className="m-0">Durée {countDays} jours</p>
      <BtnContainer className="m-0">
        <Button icon={"close"} type="transparent">
          Annuler
        </Button>
        <Button icon={"check"}>Appliquer</Button>
      </BtnContainer>
    </Grid>
  );
};

export default CalendarWrapperFooter;
