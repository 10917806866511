"use client";

import { useEffect, useState, type JSX } from "react";
import { BtnContainer, Button } from "./../../../components/buttons";
import { formatChildrenToArray, formatMultistepToJSX, formatStepsToArray, formatWithKey } from "./formatChildren";
import { MultiStepProgressBar } from "./multiStepBar/MultiStepProgressBar";

type Props = {
  children: JSX.Element[] | JSX.Element;
  buttonSize?: "medium" | "big" | "small";
  iconSend?: "check" | "send" | "edit" | "save" | "add";
  iconCancel?: "close" | "trash" | "return";
  handleSend?: (arg?: any) => void | Promise<any>;
  handleCancel?: () => void | Promise<any>;
  legendSend?: string;
  legendCancel?: string;
  type?: "create" | "update";
};

export default function MultiStepForm({
  children,
  handleSend,
  handleCancel,
  legendSend = "Valider",
  iconSend,
  iconCancel,
  legendCancel = "Annuler",
  buttonSize = "medium",
  type = "create",
}: Props) {
  const [step, setStep] = useState(0);

  const [stepArray, setStepArray] = useState(formatStepsToArray(children));
  const [childrenArray, setChildrenArray] = useState<any[]>(formatChildrenToArray(formatStepsToArray(children)[step]));

  const [errors, setErrors] = useState<{ id: string; value: string; }[][]>(formatStepsToArray(children)?.map((i) => formatWithKey(formatChildrenToArray(i), "error")));
  const [hasError, setHasError] = useState<boolean[]>(formatStepsToArray(children).map((child) => false));

  const [computedData, setComputedData] = useState<{ id: string; value: string; required?: boolean; }[][]>(formatStepsToArray(children)?.map((i) => formatWithKey(formatChildrenToArray(i), "value")));

  const handlePreSend = async () => {
    if (handleSend && (step === stepArray?.length - 1 || type === "update") && hasError.every((i) => !i)) {
      // Transform computedData to Object to facilitate fetching
      let transformation = new Map();
      computedData.forEach((stepArray) => stepArray.forEach((i) => transformation.set(i?.id, i?.value)));

      return handleSend(Object.fromEntries(transformation));
    }
  };

  const setError = (data: string, index: number) => {
    childrenArray[index]?.props.setError && childrenArray[index]?.props.setError(data);

    setErrors((prev) => [...prev.slice(0, step), [...prev[step].slice(0, index), { ...prev[step][index], value: data }, ...prev[step].slice(index + 1)], ...prev.slice(step + 1)]);
  };

  const setValue = (data: any, index: number) => {
    childrenArray[index]?.props.setter && childrenArray[index]?.props.setter(data);

    setComputedData((prev) => [...prev.slice(0, step), [...prev[step].slice(0, index), { ...prev[step][index], value: data }, ...prev[step].slice(index + 1)], ...prev.slice(step + 1)]);
  };

  const handleClick = (key: number) => {
    if (key < step) {
      setStep(key);
    } else {
      if (hasError[step] === false) {
        setStep(key);
      }
    }
  };

  const childrenUpdate = () => {
    const steps = formatStepsToArray(children);

    setStepArray(steps);
    setChildrenArray(formatChildrenToArray(steps[step]));
    setErrors(prev => steps?.map((i, index) => formatWithKey(formatChildrenToArray(i), "error", prev[index])));
    setComputedData(prev => steps?.map((i, index) => formatWithKey(formatChildrenToArray(i), "value", prev[index])));
  };

  const reset = () => {
    const steps = formatStepsToArray(children);

    setStepArray(steps);
    setChildrenArray(formatChildrenToArray(steps[step]));
    setErrors(steps?.map(i => formatWithKey(formatChildrenToArray(i), "error")));
    setComputedData(steps?.map(i => formatWithKey(formatChildrenToArray(i), "value")));

    childrenUpdate();
  };

  useEffect(() => {
    setHasError(errors.map((stepArray) => !stepArray.every((i) => i?.value === "" || i?.value === undefined)));

    return () => {
      setHasError(formatStepsToArray(children).map((child) => false));
    };
  }, [errors]);

  const onKeyPress = async (event: any) => {
    if (event.key === "Enter") {
      return handlePreSend();
    }
  };

  useEffect(() => {
    setChildrenArray(formatChildrenToArray(formatStepsToArray(children)[step]));
  }, [step]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyPress);
    childrenUpdate();

    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [children]);

  return (
    <>
      <MultiStepProgressBar steps={stepArray?.map((i) => i?.props?.legend)} step={step} handleClick={handleClick} />

      {hasError[step] && <div className="error-message mt-1 mb-1">{errors[step]?.find((i) => i?.value !== "" && i?.value !== undefined)?.value}</div>}

      {formatMultistepToJSX(children, stepArray[step]?.props?.legend, errors[step], setError, computedData[step], setValue)}

      {type === "create" && (
        <BtnContainer forceChildrenLength={2}>
          {step === 0 && (
            <>
              {handleCancel ? (
                <Button
                  data-test="cancel"
                  icon={iconCancel}
                  type="transparent"
                  size={buttonSize}
                  cb={async () => {
                    reset();
                    return handleCancel();
                  }}
                >
                  {legendCancel}
                </Button>
              ) : (
                <div></div>
              )}
            </>
          )}

          {step > 0 && stepArray.length > 1 && (
            <Button
              data-test="prev"
              icon="previous" size={buttonSize} type="transparent" cb={() => setStep((step) => step - 1)}>
              Précédent
            </Button>
          )}

          {step < stepArray.length - 1 && (
            <Button
              data-test="next"
              type="transparent" size={buttonSize} icon="next" cb={() => setStep(step + 1)} disabled={hasError[step] ||
                computedData[step]
                  .filter(i => i?.required)
                  .some((i) => i.value === "" || i.value === undefined || i.value == null)}
              tooltip={errors[step]?.find((i) => i?.value !== "" && i?.value !== undefined)?.value ?? ""}
            >
              Suivant
            </Button>
          )}

          {step == stepArray.length - 1 && (
            <Button
              data-test="send"
              size={buttonSize} icon={iconSend} type="primary" cb={handlePreSend}
              disabled={!hasError.every((i) => !i) || computedData
                .flat()
                .filter(i => i?.required)
                .some((i) => i.value === "" || i.value === undefined || i.value == null)}

              tooltip={errors?.flat()?.find((i) => i?.value !== "" && i?.value !== undefined)?.value}
            >
              {legendSend}
            </Button>
          )}
        </BtnContainer>
      )}

      {type === "update" && (
        <BtnContainer forceChildrenLength={handleCancel ? 2 : 1}>
          {handleCancel && (
            <Button
              data-test="cancel"
              icon="close"
              type={"transparent"}
              cb={() => {
                reset();
                return handleCancel();
              }}
            >
              {legendCancel}
            </Button>
          )}

          <Button
            data-test="send"
            size={buttonSize} icon={iconSend} type="primary" cb={handlePreSend}
            disabled={!hasError.every((i) => !i) || computedData
              .flat()
              .filter(i => i?.required)
              .some((i) => i.value === "" || i.value === undefined || i.value == null)}
            tooltip={errors?.flat()?.find((i) => i?.value !== "" && i?.value !== undefined)?.value}>
            {legendSend}
          </Button>
        </BtnContainer>
      )}
    </>
  );
}
