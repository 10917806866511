"use client";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { HTMLAttributes, useEffect, useState } from "react";

interface Props {
  str: string;
  search: string;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div";
  className?: string;
  style?: HTMLAttributes<HTMLDivElement>["style"];
};

export function SearchBold({ str = "", search = "", as = "div", className, style }: Props): JSX.Element {
  const Tag = `${as}` as keyof JSX.IntrinsicElements;

  const [bolded, setBolded] = useState(str);

  useEffect(() => {
    if (!search.trim()) {
      setBolded(str);
      return;
    }

    // Échapper les caractères spéciaux pour éviter les erreurs de regex
    const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // Remplacer toutes les occurrences en insérant une balise sécurisée
    const regex = new RegExp(`(${escapedSearch})`, "gi");
    const highlighted = str.replace(regex, `<span class="search-bold">$1</span>`);

    // Purifier toute la chaîne finale avant de l'afficher
    setBolded(DOMPurify.sanitize(highlighted));
  }, [str, search]);

  return <Tag style={style} className={classNames("text-left", className)} dangerouslySetInnerHTML={{ __html: bolded }} />;
}
