import { BsArrowDown, BsArrowUp } from "../../../react-icons/bs";
import { TableHeader } from "./TableHeader";

import type { JSX } from "react";

type Props = {
  sortFieldName: string;
  setSortFieldName: React.Dispatch<React.SetStateAction<string>>;
  sortFieldDirection: "desc" | "asc";
  setSortFieldDirection: React.Dispatch<React.SetStateAction<"desc" | "asc">>;
  children: JSX.Element[];
};

export function TableRow({
  sortFieldName,
  setSortFieldName,
  sortFieldDirection,
  setSortFieldDirection,
  children,
}: Props) {
  function handleSortFieldName(filedName: string) {
    setSortFieldName(filedName);
  }

  function handleSortDirection() {
    setSortFieldDirection(prev => (prev === "desc" ? "asc" : "desc"));
  }

  return (
    <tr>
      {children.map((child, index) => (
        <TableHeader key={`tabheader_${index}`} {...child.props} />
      ))}
    </tr>
  );
}
